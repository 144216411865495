import f, { createContext as m, useContext as d, useState as h, useMemo as p } from "react";
import { useEvent as g } from "./designsystem-ui-react100.js";
import { useId as E } from "./designsystem-ui-react102.js";
import { useIsoMorphicEffect as v } from "./designsystem-ui-react131.js";
import { useSyncRefs as S } from "./designsystem-ui-react108.js";
import { forwardRefWithAs as w, render as x } from "./designsystem-ui-react114.js";
let u = m(null);
function c() {
  let o = d(u);
  if (o === null) {
    let r = new Error("You used a <Description /> component, but it is not inside a relevant parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(r, c), r;
  }
  return o;
}
function M() {
  let [o, r] = h([]);
  return [o.length > 0 ? o.join(" ") : void 0, p(() => function(e) {
    let s = g((t) => (r((n) => [...n, t]), () => r((n) => {
      let i = n.slice(), l = i.indexOf(t);
      return l !== -1 && i.splice(l, 1), i;
    }))), a = p(() => ({ register: s, slot: e.slot, name: e.name, props: e.props }), [s, e.slot, e.name, e.props]);
    return f.createElement(u.Provider, { value: a }, e.children);
  }, [r])];
}
let C = "p";
function D(o, r) {
  let e = E(), { id: s = `headlessui-description-${e}`, ...a } = o, t = c(), n = S(r);
  v(() => t.register(s), [s, t.register]);
  let i = { ref: n, ...t.props, id: s };
  return x({ ourProps: i, theirProps: a, slot: t.slot || {}, defaultTag: C, name: t.name || "Description" });
}
let I = w(D), O = Object.assign(I, {});
export {
  O as Description,
  M as useDescriptions
};
