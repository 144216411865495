import d, { createContext as re, useRef as f, useState as z, useEffect as y, useMemo as te, Fragment as X, useContext as K } from "react";
import { useDisposables as he } from "./designsystem-ui-react120.js";
import { useEvent as T } from "./designsystem-ui-react100.js";
import { useFlags as pe } from "./designsystem-ui-react140.js";
import { useIsMounted as be } from "./designsystem-ui-react121.js";
import { useIsoMorphicEffect as ge } from "./designsystem-ui-react131.js";
import { useLatestValue as A } from "./designsystem-ui-react132.js";
import { useServerHandoffComplete as ne } from "./designsystem-ui-react107.js";
import { useSyncRefs as ie } from "./designsystem-ui-react108.js";
import { useTransition as Ee } from "./designsystem-ui-react142.js";
import { OpenClosedProvider as Te, State as E, useOpenClosed as le } from "./designsystem-ui-react109.js";
import { classNames as Z } from "./designsystem-ui-react143.js";
import { match as O } from "./designsystem-ui-react113.js";
import { Features as we, forwardRefWithAs as Q, RenderStrategy as C, render as ae } from "./designsystem-ui-react114.js";
function g(e = "") {
  return e.split(/\s+/).filter((t) => t.length > 1);
}
let I = re(null);
I.displayName = "TransitionContext";
var $e = ((e) => (e.Visible = "visible", e.Hidden = "hidden", e))($e || {});
function Ce() {
  let e = K(I);
  if (e === null)
    throw new Error("A <Transition.Child /> is used but it is missing a parent <Transition /> or <Transition.Root />.");
  return e;
}
function Ne() {
  let e = K(V);
  if (e === null)
    throw new Error("A <Transition.Child /> is used but it is missing a parent <Transition /> or <Transition.Root />.");
  return e;
}
let V = re(null);
V.displayName = "NestingContext";
function _(e) {
  return "children" in e ? _(e.children) : e.current.filter(({ el: t }) => t.current !== null).filter(({ state: t }) => t === "visible").length > 0;
}
function se(e, t) {
  let i = A(e), l = f([]), L = be(), R = he(), m = T((s, n = C.Hidden) => {
    let a = l.current.findIndex(({ el: r }) => r === s);
    a !== -1 && (O(n, { [C.Unmount]() {
      l.current.splice(a, 1);
    }, [C.Hidden]() {
      l.current[a].state = "hidden";
    } }), R.microTask(() => {
      var r;
      !_(l) && L.current && ((r = i.current) == null || r.call(i));
    }));
  }), N = T((s) => {
    let n = l.current.find(({ el: a }) => a === s);
    return n ? n.state !== "visible" && (n.state = "visible") : l.current.push({ el: s, state: "visible" }), () => m(s, C.Unmount);
  }), v = f([]), h = f(Promise.resolve()), u = f({ enter: [], leave: [], idle: [] }), p = T((s, n, a) => {
    v.current.splice(0), t && (t.chains.current[n] = t.chains.current[n].filter(([r]) => r !== s)), t == null || t.chains.current[n].push([s, new Promise((r) => {
      v.current.push(r);
    })]), t == null || t.chains.current[n].push([s, new Promise((r) => {
      Promise.all(u.current[n].map(([P, F]) => F)).then(() => r());
    })]), n === "enter" ? h.current = h.current.then(() => t == null ? void 0 : t.wait.current).then(() => a(n)) : a(n);
  }), c = T((s, n, a) => {
    Promise.all(u.current[n].splice(0).map(([r, P]) => P)).then(() => {
      var r;
      (r = v.current.shift()) == null || r();
    }).then(() => a(n));
  });
  return te(() => ({ children: l, register: N, unregister: m, onStart: p, onStop: c, wait: h, chains: u }), [N, m, l, p, c, u, h]);
}
function Pe() {
}
let Fe = ["beforeEnter", "afterEnter", "beforeLeave", "afterLeave"];
function ee(e) {
  var t;
  let i = {};
  for (let l of Fe)
    i[l] = (t = e[l]) != null ? t : Pe;
  return i;
}
function Se(e) {
  let t = f(ee(e));
  return y(() => {
    t.current = ee(e);
  }, [e]), t;
}
let Le = "div", oe = we.RenderStrategy;
function Re(e, t) {
  var i, l;
  let { beforeEnter: L, afterEnter: R, beforeLeave: m, afterLeave: N, enter: v, enterFrom: h, enterTo: u, entered: p, leave: c, leaveFrom: s, leaveTo: n, ...a } = e, r = f(null), P = ie(r, t), F = (i = a.unmount) == null || i ? C.Unmount : C.Hidden, { show: o, appear: w, initial: W } = Ce(), [$, j] = z(o ? "visible" : "hidden"), Y = Ne(), { register: D, unregister: H } = Y;
  y(() => D(r), [D, r]), y(() => {
    if (F === C.Hidden && r.current) {
      if (o && $ !== "visible") {
        j("visible");
        return;
      }
      return O($, { hidden: () => H(r), visible: () => D(r) });
    }
  }, [$, r, D, H, o, F]);
  let k = A({ base: g(a.className), enter: g(v), enterFrom: g(h), enterTo: g(u), entered: g(p), leave: g(c), leaveFrom: g(s), leaveTo: g(n) }), M = Se({ beforeEnter: L, afterEnter: R, beforeLeave: m, afterLeave: N }), q = ne();
  y(() => {
    if (q && $ === "visible" && r.current === null)
      throw new Error("Did you forget to passthrough the `ref` to the actual DOM node?");
  }, [r, $, q]);
  let ce = W && !w, J = w && o && W, de = !q || ce ? "idle" : o ? "enter" : "leave", x = pe(0), fe = T((b) => O(b, { enter: () => {
    x.addFlag(E.Opening), M.current.beforeEnter();
  }, leave: () => {
    x.addFlag(E.Closing), M.current.beforeLeave();
  }, idle: () => {
  } })), me = T((b) => O(b, { enter: () => {
    x.removeFlag(E.Opening), M.current.afterEnter();
  }, leave: () => {
    x.removeFlag(E.Closing), M.current.afterLeave();
  }, idle: () => {
  } })), U = se(() => {
    j("hidden"), H(r);
  }, Y), B = f(!1);
  Ee({ immediate: J, container: r, classes: k, direction: de, onStart: A((b) => {
    B.current = !0, U.onStart(r, b, fe);
  }), onStop: A((b) => {
    B.current = !1, U.onStop(r, b, me), b === "leave" && !_(U) && (j("hidden"), H(r));
  }) });
  let S = a, ve = { ref: P };
  return J ? S = { ...S, className: Z(a.className, ...k.current.enter, ...k.current.enterFrom) } : B.current && (S.className = Z(a.className, (l = r.current) == null ? void 0 : l.className), S.className === "" && delete S.className), d.createElement(V.Provider, { value: U }, d.createElement(Te, { value: O($, { visible: E.Open, hidden: E.Closed }) | x.flags }, ae({ ourProps: ve, theirProps: S, defaultTag: Le, features: oe, visible: $ === "visible", name: "Transition.Child" })));
}
function xe(e, t) {
  let { show: i, appear: l = !1, unmount: L = !0, ...R } = e, m = f(null), N = ie(m, t);
  ne();
  let v = le();
  if (i === void 0 && v !== null && (i = (v & E.Open) === E.Open), ![!0, !1].includes(i))
    throw new Error("A <Transition /> is used but it is missing a `show={true | false}` prop.");
  let [h, u] = z(i ? "visible" : "hidden"), p = se(() => {
    u("hidden");
  }), [c, s] = z(!0), n = f([i]);
  ge(() => {
    c !== !1 && n.current[n.current.length - 1] !== i && (n.current.push(i), s(!1));
  }, [n, i]);
  let a = te(() => ({ show: i, appear: l, initial: c }), [i, l, c]);
  y(() => {
    if (i)
      u("visible");
    else if (!_(p))
      u("hidden");
    else {
      let o = m.current;
      if (!o)
        return;
      let w = o.getBoundingClientRect();
      w.x === 0 && w.y === 0 && w.width === 0 && w.height === 0 && u("hidden");
    }
  }, [i, p]);
  let r = { unmount: L }, P = T(() => {
    var o;
    c && s(!1), (o = e.beforeEnter) == null || o.call(e);
  }), F = T(() => {
    var o;
    c && s(!1), (o = e.beforeLeave) == null || o.call(e);
  });
  return d.createElement(V.Provider, { value: p }, d.createElement(I.Provider, { value: a }, ae({ ourProps: { ...r, as: X, children: d.createElement(ue, { ref: N, ...r, ...R, beforeEnter: P, beforeLeave: F }) }, theirProps: {}, defaultTag: X, features: oe, visible: h === "visible", name: "Transition" })));
}
function Oe(e, t) {
  let i = K(I) !== null, l = le() !== null;
  return d.createElement(d.Fragment, null, !i && l ? d.createElement(G, { ref: t, ...e }) : d.createElement(ue, { ref: t, ...e }));
}
let G = Q(xe), ue = Q(Re), ye = Q(Oe), Ke = Object.assign(G, { Child: ye, Root: G });
export {
  Ke as Transition
};
