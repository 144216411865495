import { jsxs as T, jsx as r } from "react/jsx-runtime";
import { useState as g, useRef as A, Children as D, useCallback as f, useEffect as $, isValidElement as O } from "react";
import { cn as d } from "./designsystem-ui-react46.js";
import { Button as j } from "./designsystem-ui-react6.js";
import { Link as G } from "./designsystem-ui-react21.js";
import _ from "./designsystem-ui-react57.js";
import q from "./designsystem-ui-react58.js";
function F({
  ariaLabel: h = "Carousel",
  children: v,
  className: x,
  header: p,
  href: y,
  linkTitle: E,
  numVisible: e = { default: 1.2, sm: 2.2, md: 3, lg: 3, xl: 3 },
  size: S = "full"
}) {
  const [n, N] = g(0), [W, L] = g(!1), [I, M] = g(!0), [l, m] = g(e.default), u = A(null), z = D.toArray(v), s = z.length, C = ((t) => {
    const o = {
      sm: "tw-w-full sm:tw-w-[400px]",
      md: "tw-w-full sm:tw-w-[600px] md:tw-w-[800px]",
      lg: "tw-w-full sm:tw-w-[800px] md:tw-w-[1000px]",
      xl: "tw-w-full sm:tw-w-[1000px] md:tw-w-[1200px]",
      full: "tw-w-full"
    };
    return typeof t == "number" ? `tw-w-full sm:tw-w-[${t}px]` : o[t] || o.full;
  })(S), b = f(() => {
    const t = window.innerWidth;
    t < 640 && e.default ? m(e.default) : t < 768 && e.sm ? m(e.sm) : t < 1024 && e.md ? m(e.md) : t < 1280 && e.lg ? m(e.lg) : e.xl ? m(e.xl) : m(e.default);
    const o = s > l;
    L(o && n > 0), M(o && n < s - l);
  }, [e, s, l, n]);
  $(() => (b(), window.addEventListener("resize", b), () => {
    window.removeEventListener("resize", b);
  }), [b]);
  const a = f(
    (t) => {
      if (u.current) {
        const o = u.current, c = o.clientWidth / l;
        o.scrollTo({
          left: t * c,
          behavior: "smooth"
        }), N(t);
      }
    },
    [l]
  ), B = f(() => {
    const t = Math.max(n - l, 0);
    a(t);
  }, [n, a, l]), P = f(() => {
    const t = Math.min(n + l, s - l);
    a(t);
  }, [n, s, l, a]), k = f(() => {
    if (u.current) {
      const t = u.current, o = t.clientWidth / l, c = t.scrollWidth - t.clientWidth, i = t.scrollLeft;
      if (Math.abs(c - i) < 1) {
        const w = Math.ceil(s - l);
        N(w);
      } else {
        const w = Math.round(i / o);
        w !== n && N(w);
      }
    }
  }, [n, l, s]);
  $(() => {
    const t = u.current;
    if (t) {
      let o, c, i;
      const w = () => {
        clearTimeout(o), o = window.setTimeout(() => {
          clearTimeout(c), window.innerWidth >= 768 && (c = window.setTimeout(() => {
            a(n);
          }, 150)), clearTimeout(i), i = window.setTimeout(() => {
            L(n > 0), M(n < s - l);
          }, 50);
        }, 100);
      };
      return t.addEventListener("scroll", k), t.addEventListener("scroll", w), () => {
        t.removeEventListener("scroll", k), t.removeEventListener("scroll", w), clearTimeout(o), clearTimeout(i);
      };
    }
  }, [n, k, a, l, s]);
  const R = {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::WebkitScrollbar": {
      display: "none"
    }
  };
  return /* @__PURE__ */ T("div", { className: d("tw-flex tw-w-full tw-h-full tw-relative tw-flex-col tw-items-center tw-min-w-96", x), children: [
    p ? /* @__PURE__ */ T("div", { className: d("tw-flex tw-justify-between tw-items-center tw-pl-4 tw-mr-auto", C), children: [
      /* @__PURE__ */ r("h2", { className: "tw-text-xl tw-font-bold tw-text-gray-900", children: p }),
      /* @__PURE__ */ r(G, { href: y, children: E })
    ] }) : null,
    /* @__PURE__ */ T(
      "div",
      {
        "aria-label": h,
        "aria-live": "polite",
        className: d("tw-flex tw-relative tw-h-full tw-flex-row tw-items-center tw-mr-auto", C),
        role: "region",
        children: [
          /* @__PURE__ */ r(
            j,
            {
              "aria-label": "Previous",
              className: d("tw-absolute tw-hidden -tw-left-16 md:tw-block tw-z-10", W ? "" : "tw-invisible"),
              disabled: !W,
              icon: /* @__PURE__ */ r(_, {}),
              onClick: B,
              size: "iconOnly",
              variant: "secondary"
            }
          ),
          /* @__PURE__ */ r(
            "div",
            {
              "aria-live": "polite",
              className: d(
                "tw-overflow-x-auto tw-flex tw-relative md:tw-snap-x md:tw-snap-mandatory tw-scroll-smooth",
                C
              ),
              ref: u,
              role: "list",
              style: R,
              children: z.map((t, o) => {
                const i = O(t) && t.key ? `${t.key}-${o}` : `carousel-item-${o}`;
                return /* @__PURE__ */ r(
                  "div",
                  {
                    className: "tw-flex-shrink-0 tw-flex tw-justify-center md:tw-snap-center",
                    role: "listitem",
                    style: { width: `calc(100% / ${l})` },
                    children: t
                  },
                  i
                );
              })
            }
          ),
          /* @__PURE__ */ r(
            j,
            {
              "aria-label": "Next",
              className: d("tw-absolute -tw-right-16 tw-hidden md:tw-block", I ? "" : "tw-invisible"),
              disabled: !I,
              icon: /* @__PURE__ */ r(q, {}),
              onClick: P,
              size: "iconOnly"
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ r(
      H,
      {
        currentIndex: n,
        onDotClick: a,
        totalItems: s,
        visibleItems: l
      }
    )
  ] });
}
F.displayName = "Carousel";
function H({ totalItems: h, visibleItems: v, currentIndex: x, onDotClick: p }) {
  const y = Math.max(1, h - Math.floor(v) + 1);
  return /* @__PURE__ */ r("div", { className: "tw-flex tw-justify-center tw-gap-2 tw-mt-4 md:tw-hidden", children: Array.from({ length: y }).map((E, e) => {
    const S = e === y - 1 ? x >= h - v : x === e;
    return /* @__PURE__ */ r(
      "button",
      {
        "aria-label": `Go to slide ${e + 1}`,
        className: d(
          "tw-w-3 tw-h-3 tw-rounded-full transition-colors",
          S ? "tw-bg-surface-action" : "tw-bg-surface-default-hover"
          // Inactive
        ),
        onClick: () => {
          p(e);
        },
        type: "button"
      },
      `dot-${e}`
    );
  }) });
}
export {
  F as Carousel
};
