import { jsxs as w, jsx as t } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
import { FormDetails as u } from "./designsystem-ui-react14.js";
import m from "./designsystem-ui-react87.js";
function p({
  className: o,
  isLabelHidden: r = !0,
  placeholder: i,
  label: e,
  onChange: s,
  isDisabled: l,
  autoComplete: n = "off",
  onKeyDown: d,
  value: f,
  ref: c,
  ...b
}) {
  return /* @__PURE__ */ w("search", { className: a("tw-flex tw-flex-col tw-gap-y-2 tw-w-full", o), children: [
    r ? null : /* @__PURE__ */ t(u, { isLabelHidden: r, label: e }),
    /* @__PURE__ */ w("div", { className: "tw-relative tw-overflow-clip tw-flex tw-items-center tw-rounded-full [&:has(:focus-visible)]:tw-ring tw-ring-information-600", children: [
      /* @__PURE__ */ t("div", { className: "tw-absolute tw-left-2 tw-pointer-events-none tw-fill-ink-disabled", children: /* @__PURE__ */ t(m, { className: "tw-w-6 tw-h-6" }) }),
      /* @__PURE__ */ t(
        "input",
        {
          autoComplete: n,
          className: a(
            "tw-block tw-w-full tw-text-base tw-rounded-full tw-bg-base-50 tw-p-2 tw-text-inkg-default  placeholder:tw-text-ink-disabled tw-text-ink-brand-default",
            // border
            "tw-border-2 tw-border-base-400 hover:tw-border-ink-brand-default",
            // focus
            "focus-visible:tw-border-link-default tw-outline-none focus-visible:tw-bg-base-0",
            // disabled
            l && "tw-cursor-not-allowed tw-bg-base-50 tw-text-ink-disabled tw-border-base-300",
            "tw-pl-8"
          ),
          disabled: l,
          id: e,
          name: e,
          onChange: s,
          onKeyDown: d,
          placeholder: i,
          ref: c,
          type: "search",
          value: f,
          ...b
        }
      )
    ] })
  ] });
}
p.displayName = "Search";
export {
  p as Search
};
