import { __module as r } from "./designsystem-ui-react168.js";
import { l as p } from "./designsystem-ui-react169.js";
(function(n, o) {
  Object.defineProperty(o, "__esModule", {
    value: !0
  });
  var u = p, d = f(u);
  function f(e) {
    return e && e.__esModule ? e : { default: e };
  }
  o.default = function(e) {
    var l = new Promise(function(t) {
      if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
        t(window.YT);
        return;
      } else {
        var w = window.location.protocol === "http:" ? "http:" : "https:";
        (0, d.default)(w + "//www.youtube.com/iframe_api", function(a) {
          a && e.trigger("error", a);
        });
      }
      var i = window.onYouTubeIframeAPIReady;
      window.onYouTubeIframeAPIReady = function() {
        i && i(), t(window.YT);
      };
    });
    return l;
  }, n.exports = o.default;
})(r, r.exports);
var s = r.exports;
export {
  s as l
};
