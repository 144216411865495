// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import footerCowUrl from "url:/src/img/themes/tine/footer_cow.svg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import footerGrassUrl from "url:/src/img/themes/tine/footer_grass.svg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import desktopLogoUrl from "url:/src/img/themes/tine/logo_blue.svg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import mobileLogoUrl from "url:/src/img/themes/tine/logo_white.svg";

import { apiClient } from "../common/apiClient";
import { CustomerFeature } from "../common/types/featureTypes";
import { Invoice } from "../common/types/invoiceTypes";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { SiteConfig } from "../common/types/themeTypes";

import authStore from "../stores/auth/authStore";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "4RRKWA9QAI";
const ALGOLIA_SEARCHKEY = "b13d7842783d41922109815b23b958a3";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

const tineSiteConfig: SiteConfig = {
   storeId: "tinehandel",
   storeCompanyName: "TINE",
   orderSupportEmail: "kundeservice@tine.no",
   siteName: "TINE Handel",
   m3CompanyNumber: "100",
   m3DivisionNumber: "102",
   footerDecorationImageUrl1: footerGrassUrl.toString(),
   footerDecorationImageUrl2: footerCowUrl.toString(),
   productDropdownEnabled: true,
   showComparisonPrice: true,
   storeLogo: {
      searchbar: {
         url: desktopLogoUrl.toString(),
         width: 50
      },
      searchbarMobile: {
         url: mobileLogoUrl.toString(),
         width: 40
      },
      footer: {
         url: mobileLogoUrl.toString(),
         width: 72,
         mobileWidth: 50
      }
   },
   faqSlug: "/kundeservice/ofte-stilte-sporsmal",
   baseUrl: "https://www.tinehandel.no",
   filterAttributes: () => ({
      brand: "Merkevare",
      taste: "Smak",
      size: "Størrelse",
      certifications: "Sertifisering"
   }),
   papirflyApsCustomerId: "12590",
   papirflyApsImagePrefix: "",
   enhanceProduct: (product) => product,
   filterRefreshOnChange: true,
   tipApiPrefix: "",
   fetchCustomerFeatures: async (customerNumber: string) => {
      const url = `${process.env.API_HOST}/api/features/${customerNumber}.json`;
      return (await apiClient(url, authStore.getSessionToken()).get().json()) as CustomerFeature[];
   },
   invoiceTooltipText: (invoice: Invoice) =>
      `Intrum saksnr. er:${invoice.debtCollectionCaseNumber}. Kontaktes på Inkasso telefonnr.:+4723211000`,

   deliveryFee: {
      enabled: true
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://kundeskjema.tine.no/",
   deliveryNotes: {
      visible: true
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "inspirasjon",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Inspiration" : "Matfaglig inspirasjon"),
            homePath: () => "/matfaglig",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: () => "Se all matfaglig inspirasjon"
         },
         {
            type: "recipe",
            name: "oppskrifter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Recipes" : "Oppskrifter"),
            homePath: (_locale) => "/oppskrifter",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: () => "Se alle oppskrifter"
         }
      ]
   },
   contentImages: {
      cloudinaryMapping: "content_images"
   },
   contactForm: {
      apiPath: "/api/contactform",
      reasons: [
         { value: "delivery", label: "Spørsmål angående levering", label_en: "Questions about ordering" },
         { value: "invoice", label: "Endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "invoice_credit", label: "Spørsmål angående faktura", label_en: "Invoice inquiries" },
         { value: "other", label: "Annet", label_en: "Other" },
         { value: "product_order", label: "Spørsmål angående bestillling", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål angående produkter", label_en: "Question about products" },
         {
            value: "refrigeration_equipment",
            label: "Spørsmål angående kjølemøbler",
            label_en: "Questions about refrigeration equipment"
         }
      ]
   },
   whileYouWaitMessage:
      "Ta frem en Go'morgen yoghurt fra kjøleskapet, len deg tilbake og nyt et par sekunders velfortjent pause!",
   notifications: {
      categories: [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "IMPORTANT_INFO_ABOUT_DELIVERY", name: "Viktig melding om levering" },
         { id: "ORDER_DEADLINE", name: "Påminnelse om ordrefrist" }
      ],
      enableDeliveryMessages: true
   },
   cart: {
      customerReferenceLabel: "Din betalingsreferanse"
   }
};

export default tineSiteConfig;
