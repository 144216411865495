import { transition as m } from "./designsystem-ui-react160.js";
import { disposables as f } from "./designsystem-ui-react136.js";
import { useDisposables as l } from "./designsystem-ui-react120.js";
import { useIsMounted as M } from "./designsystem-ui-react121.js";
import { useIsoMorphicEffect as i } from "./designsystem-ui-react131.js";
import { useLatestValue as b } from "./designsystem-ui-react132.js";
function L({ immediate: t, container: n, direction: s, classes: a, onStart: u, onStop: c }) {
  let p = M(), d = l(), r = b(s);
  i(() => {
    t && (r.current = "enter");
  }, [t]), i(() => {
    let e = f();
    d.add(e.dispose);
    let o = n.current;
    if (o && r.current !== "idle" && p.current)
      return e.dispose(), u.current(r.current), e.add(m(o, a.current, r.current === "enter", () => {
        e.dispose(), c.current(r.current);
      })), e.dispose;
  }, [s]);
}
export {
  L as useTransition
};
