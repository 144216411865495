import { view } from "@risingstack/react-easy-state";
import { Group } from "@tine/designsystem-ui-react";
import { Link } from "wouter";

import MainColumn from "../../components/common/MainColumn";

import SearchField from "../../components/search/SearchField";
import StoreLogoComponent from "../../components/StoreLogoComponent";

const MobileSearchBar = () => {
   return (
      <div className="tw-w-full tw-bg-headerFooterBackground">
         <MainColumn>
            <Group>
               <div className="tw-flex tw-items-center tw-py-2">
                  <Link to="/">
                     <StoreLogoComponent placement="searchbarMobile" />
                  </Link>
               </div>
               <div className="tw-flex-auto tw-py-2">
                  <SearchField />
               </div>
            </Group>
         </MainColumn>
      </div>
   );
};

export default view(MobileSearchBar);
