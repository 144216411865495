import { jsxs as i, jsx as t } from "react/jsx-runtime";
import { isEmptyArray as q, cn as a } from "./designsystem-ui-react46.js";
import { FormDetails as B } from "./designsystem-ui-react14.js";
import { FormError as S } from "./designsystem-ui-react15.js";
import { Listbox as o } from "./designsystem-ui-react83.js";
import D from "./designsystem-ui-react49.js";
function E({
  label: v,
  isLabelHidden: u = !1,
  className: h,
  value: x,
  options: w,
  description: g,
  onChange: p,
  isDisabled: d,
  placeholder: k = "Select",
  tooltipPosition: N = "right",
  tooltipText: y = "",
  errorMessage: O = "",
  showErrorMessage: c = !1,
  required: j,
  optGroup: b = !1,
  isNative: T = !1,
  ...s
}) {
  var F;
  const f = Array.isArray(w[0]) || (F = w[0]) != null && F.options ? w.flatMap((l) => l.options) : w;
  if (q(f))
    return /* @__PURE__ */ i("div", { children: [
      /* @__PURE__ */ t("h2", { children: "Error:" }),
      /* @__PURE__ */ t("p", { children: "No options, or invalid options provided." })
    ] });
  const { label: A = "" } = x;
  return T ? /* @__PURE__ */ i("div", { className: a("tw-flex tw-flex-col tw-gap-2 tw-full tw-relative", u && "tw-gap-0", h), children: [
    /* @__PURE__ */ t(
      B,
      {
        description: g,
        isDisabled: d,
        isLabelHidden: u,
        label: v,
        tooltipPosition: N,
        tooltipText: y
      }
    ),
    /* @__PURE__ */ i(
      "select",
      {
        className: a(
          "tw-w-full tw-p-2 tw-rounded tw-border tw-border-ink-brand-default tw-text-base tw-transition-all tw-ease-in-out tw-duration-200 tw-cursor-pointer",
          "hover:tw-border-ink-brand-default-TODO:fix-hover focus:tw-border-ink-brand-default-TODO:fix-focus focus:tw-ring-2 focus:tw-ring-information-600 focus:tw-outline-none",
          d && "!tw-bg-base-50 !tw-text-ink-disabled !tw-cursor-not-allowed",
          c && "!tw-border-error-600"
        ),
        disabled: d,
        onChange: (l) => {
          const e = f.find((r) => r.value === l.target.value);
          e && p(e);
        },
        value: A,
        ...s,
        children: [
          /* @__PURE__ */ t("option", { disabled: !0, value: "", children: k }),
          b ? Array.isArray(w) && w.map((l, e) => /* @__PURE__ */ t("optgroup", { label: l.optGroupLabel || l.label, children: l.options.map((r) => /* @__PURE__ */ t("option", { disabled: r.isDisabled, value: r.value, children: r.label }, r.id)) }, `group-${e}`)) : null,
          !b && f.map((l) => /* @__PURE__ */ t("option", { disabled: l.isDisabled, value: l.value, children: l.label }, l.id))
        ]
      }
    ),
    /* @__PURE__ */ t(S, { errorMessage: O, showErrorMessage: c })
  ] }) : /* @__PURE__ */ i("div", { className: a("tw-flex tw-flex-col tw-gap-2 tw-full tw-relative", h), children: [
    /* @__PURE__ */ t(o, { disabled: d, onChange: p, value: x, children: ({ open: l }) => /* @__PURE__ */ i(
      "div",
      {
        onBlur: (e) => {
          !e.currentTarget.contains(e.relatedTarget) && s.onBlur && s.onBlur(e);
        },
        className: a(
          "tw-flex tw-relative tw-flex-col tw-w-full tw-gap-2",
          u && "tw-gap-0",
          l && "tw-z-10"
        ),
        children: [
          /* @__PURE__ */ t(
            B,
            {
              description: g,
              isDisabled: d,
              isLabelHidden: u,
              label: v,
              tooltipPosition: N,
              tooltipText: y
            }
          ),
          /* @__PURE__ */ i("div", { className: a("tw-relative tw-w-full tw-bg-surface-default", l && "tw-drop-shadow-lg"), children: [
            /* @__PURE__ */ i(
              o.Button,
              {
                "aria-required": j,
                className: a(
                  "tw-text-left tw-w-full tw-cursor-pointer tw-text-base tw-p-2 tw-transition-all tw-ease-in-out tw-rounded tw-duration-200 tw-flex tw-items-center tw-justify-between",
                  "tw-border-ink-disabled tw-border-2 hover:tw-border-ink-brand-default",
                  // focus
                  "focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
                  l ? "tw-bg-surface-action tw-text-ink-brand-inverted tw-border-surface-action tw-rounded-b-none" : "tw-bg-surface-default tw-border-surface-ink-brand-default tw-rounded tw-text-ink-brand-default",
                  d && "!tw-bg-base-50 !tw-text-ink-disabled !tw-cursor-not-allowed ",
                  c && "!tw-border-error-600"
                ),
                children: [
                  A || k,
                  /* @__PURE__ */ t(
                    D,
                    {
                      "aria-hidden": "true",
                      className: a(
                        "tw-transition-all tw-ease-in-out tw-duration-300 tw-text-base tw-w-5 tw-h-5",
                        l ? "tw-rotate-180 tw-fill-ink-brand-inverted" : "tw-text-base-900 tw-rotate-0 tw-fill-ink-brand-default",
                        d && "tw-fill-ink-disabled"
                      )
                    }
                  )
                ]
              }
            ),
            /* @__PURE__ */ t(
              o.Options,
              {
                onFocus: (e) => {
                  s.onFocus && s.onFocus(e);
                },
                className: a(
                  // basic layout
                  "tw-absolute tw-z-10 tw-block tw-left-0 tw-overflow-clip tw-right-0 tw-w-full tw-rounded-b tw-bg-surface-default tw-m-0 tw-p-0 focus:tw-outline-none",
                  // reset
                  "tw-list-none"
                ),
                children: b && Array.isArray(w) ? w.map((e, r) => /* @__PURE__ */ i("div", { children: [
                  /* @__PURE__ */ t("div", { className: "tw-px-2 tw-py-1 tw-text-sm tw-font-semibold tw-text-ink-light", children: e.optGroupLabel || e.label }),
                  e.options.map((n) => /* @__PURE__ */ t(o.Option, { disabled: n.isDisabled, value: n, children: ({ selected: m, active: z, disabled: $ }) => /* @__PURE__ */ t(
                    "div",
                    {
                      className: a(
                        "tw-relative tw-flex tw-cursor-default tw-select-none tw-p-2 tw-text-base tw-pl-4",
                        (m || z) && "tw-bg-surface-default-hover tw-cursor-pointer",
                        $ && "tw-text-ink-disabled tw-cursor-not-allowed tw-bg-base-50"
                      ),
                      children: /* @__PURE__ */ t("span", { children: n.label })
                    }
                  ) }, n.id))
                ] }, `group-${r}`)) : f.map((e) => /* @__PURE__ */ t(o.Option, { disabled: e.isDisabled, value: e, children: ({ selected: r, active: n, disabled: m }) => /* @__PURE__ */ t(
                  "div",
                  {
                    className: a(
                      "tw-relative tw-flex tw-cursor-default tw-select-none tw-p-2 tw-text-base",
                      (r || n) && "tw-bg-surface-default-hover tw-cursor-pointer",
                      m && "tw-text-ink-disabled tw-cursor-not-allowed tw-bg-base-50"
                    ),
                    children: /* @__PURE__ */ t("span", { children: e.label })
                  }
                ) }, e.id))
              }
            )
          ] })
        ]
      }
    ) }),
    /* @__PURE__ */ t(S, { errorMessage: O, showErrorMessage: c })
  ] });
}
E.displayName = "Select";
export {
  E as Select
};
