import { jsx as p, jsxs as d } from "react/jsx-runtime";
import { cn as l } from "./designsystem-ui-react46.js";
function h({
  children: n,
  icon: t = null,
  iconPosition: e = "right",
  className: s,
  as: i = "button",
  checked: r = !1,
  onClick: o,
  disabled: w,
  ...b
}) {
  const a = !!t;
  return /* @__PURE__ */ p(
    i,
    {
      ...b,
      "aria-pressed": r,
      className: l(
        // common
        "tw-bg-chip-default tw-rounded-full tw-p-2 tw-cursor-pointer tw-flex tw-items-center",
        // Border
        "tw-border-button-primary-bg",
        // Hover
        "hover:tw-bg-chip-default-hover",
        // for hasIcon
        a && "tw-no-underline [&>svg]:tw-ring",
        // for checked
        r && "tw-bg-chip-active tw-text-base-0 hover:tw-bg-chip-active-hover",
        // for disabled
        w && "tw-bg-button-disabled-bg tw-text-ink-disabled hover:tw-bg-button-disabled-bg",
        // for override
        s
      ),
      onClick: o,
      children: /* @__PURE__ */ d("span", { className: l(a && "tw-flex tw-items-center tw-gap-x-1"), children: [
        e === "left" ? t : null,
        n,
        e === "right" ? t : null
      ] })
    }
  );
}
h.displayName = "Chip";
export {
  h as Chip
};
