import { match as F } from "./designsystem-ui-react113.js";
import { getOwnerDocument as v } from "./designsystem-ui-react118.js";
let d = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e) => `${e}:not([tabindex='-1'])`).join(",");
var b = ((e) => (e[e.First = 1] = "First", e[e.Previous = 2] = "Previous", e[e.Next = 4] = "Next", e[e.Last = 8] = "Last", e[e.WrapAround = 16] = "WrapAround", e[e.NoScroll = 32] = "NoScroll", e))(b || {}), N = ((e) => (e[e.Error = 0] = "Error", e[e.Overflow = 1] = "Overflow", e[e.Success = 2] = "Success", e[e.Underflow = 3] = "Underflow", e))(N || {}), h = ((e) => (e[e.Previous = -1] = "Previous", e[e.Next = 1] = "Next", e))(h || {});
function x(e = document.body) {
  return e == null ? [] : Array.from(e.querySelectorAll(d)).sort((t, r) => Math.sign((t.tabIndex || Number.MAX_SAFE_INTEGER) - (r.tabIndex || Number.MAX_SAFE_INTEGER)));
}
var y = ((e) => (e[e.Strict = 0] = "Strict", e[e.Loose = 1] = "Loose", e))(y || {});
function I(e, t = 0) {
  var r;
  return e === ((r = v(e)) == null ? void 0 : r.body) ? !1 : F(t, { 0() {
    return e.matches(d);
  }, 1() {
    let o = e;
    for (; o !== null; ) {
      if (o.matches(d))
        return !0;
      o = o.parentElement;
    }
    return !1;
  } });
}
var p = ((e) => (e[e.Keyboard = 0] = "Keyboard", e[e.Mouse = 1] = "Mouse", e))(p || {});
typeof window < "u" && typeof document < "u" && (document.addEventListener("keydown", (e) => {
  e.metaKey || e.altKey || e.ctrlKey || (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0), document.addEventListener("click", (e) => {
  e.detail === 1 ? delete document.documentElement.dataset.headlessuiFocusVisible : e.detail === 0 && (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0));
function g(e) {
  e == null || e.focus({ preventScroll: !0 });
}
let w = ["textarea", "input"].join(",");
function O(e) {
  var t, r;
  return (r = (t = e == null ? void 0 : e.matches) == null ? void 0 : t.call(e, w)) != null ? r : !1;
}
function S(e, t = (r) => r) {
  return e.slice().sort((r, o) => {
    let l = t(r), i = t(o);
    if (l === null || i === null)
      return 0;
    let n = l.compareDocumentPosition(i);
    return n & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : n & Node.DOCUMENT_POSITION_PRECEDING ? 1 : 0;
  });
}
function L(e, t, { sorted: r = !0, relativeTo: o = null, skipElements: l = [] } = {}) {
  let i = Array.isArray(e) ? e.length > 0 ? e[0].ownerDocument : document : e.ownerDocument, n = Array.isArray(e) ? r ? S(e) : e : x(e);
  l.length > 0 && n.length > 1 && (n = n.filter((u) => !l.includes(u))), o = o ?? i.activeElement;
  let m = (() => {
    if (t & 5)
      return 1;
    if (t & 10)
      return -1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), f = (() => {
    if (t & 1)
      return 0;
    if (t & 2)
      return Math.max(0, n.indexOf(o)) - 1;
    if (t & 4)
      return Math.max(0, n.indexOf(o)) + 1;
    if (t & 8)
      return n.length - 1;
    throw new Error("Missing Focus.First, Focus.Previous, Focus.Next or Focus.Last");
  })(), E = t & 32 ? { preventScroll: !0 } : {}, c = 0, a = n.length, s;
  do {
    if (c >= a || c + a <= 0)
      return 0;
    let u = f + c;
    if (t & 16)
      u = (u + a) % a;
    else {
      if (u < 0)
        return 3;
      if (u >= a)
        return 1;
    }
    s = n[u], s == null || s.focus(E), c += m;
  } while (s !== i.activeElement);
  return t & 6 && O(s) && s.select(), 2;
}
export {
  b as Focus,
  N as FocusResult,
  y as FocusableMode,
  g as focusElement,
  L as focusIn,
  x as getFocusableElements,
  I as isFocusableElement,
  S as sortByDomNode
};
