import { useRef as o } from "react";
import { getTextValue as s } from "./designsystem-ui-react159.js";
import { useEvent as f } from "./designsystem-ui-react100.js";
function l(i) {
  let r = o(""), t = o("");
  return f(() => {
    let e = i.current;
    if (!e)
      return "";
    let u = e.innerText;
    if (r.current === u)
      return t.current;
    let n = s(e).trim().toLowerCase();
    return r.current = u, t.current = n, n;
  });
}
export {
  l as useTextValue
};
