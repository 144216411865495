import F, { useRef as T } from "react";
import { useDisposables as C } from "./designsystem-ui-react120.js";
import { useEvent as k } from "./designsystem-ui-react100.js";
import { useEventListener as I } from "./designsystem-ui-react101.js";
import { useIsMounted as H } from "./designsystem-ui-react121.js";
import { useOnUnmount as N } from "./designsystem-ui-react122.js";
import { useOwnerDocument as O } from "./designsystem-ui-react105.js";
import { useServerHandoffComplete as W } from "./designsystem-ui-react107.js";
import { useSyncRefs as _ } from "./designsystem-ui-react108.js";
import { useTabDirection as j, Direction as v } from "./designsystem-ui-react123.js";
import { useWatch as g } from "./designsystem-ui-react124.js";
import { Hidden as h, Features as y } from "./designsystem-ui-react125.js";
import { history as b } from "./designsystem-ui-react126.js";
import { focusIn as E, Focus as d, focusElement as i, FocusResult as x } from "./designsystem-ui-react127.js";
import { match as $ } from "./designsystem-ui-react113.js";
import { microTask as B } from "./designsystem-ui-react128.js";
import { forwardRefWithAs as U, render as q } from "./designsystem-ui-react114.js";
function M(e) {
  if (!e)
    return /* @__PURE__ */ new Set();
  if (typeof e == "function")
    return new Set(e());
  let r = /* @__PURE__ */ new Set();
  for (let t of e.current)
    t.current instanceof HTMLElement && r.add(t.current);
  return r;
}
let z = "div";
var A = ((e) => (e[e.None = 1] = "None", e[e.InitialFocus = 2] = "InitialFocus", e[e.TabLock = 4] = "TabLock", e[e.FocusLock = 8] = "FocusLock", e[e.RestoreFocus = 16] = "RestoreFocus", e[e.All = 30] = "All", e))(A || {});
function G(e, r) {
  let t = T(null), n = _(t, r), { initialFocus: a, containers: l, features: o = 30, ...u } = e;
  W() || (o = 1);
  let f = O(t);
  V({ ownerDocument: f }, !!(o & 16));
  let c = X({ ownerDocument: f, container: t, initialFocus: a }, !!(o & 2));
  Y({ ownerDocument: f, container: t, containers: l, previousActiveElement: c }, !!(o & 8));
  let D = j(), L = k((s) => {
    let m = t.current;
    m && ((p) => p())(() => {
      $(D.current, { [v.Forwards]: () => {
        E(m, d.First, { skipElements: [s.relatedTarget] });
      }, [v.Backwards]: () => {
        E(m, d.Last, { skipElements: [s.relatedTarget] });
      } });
    });
  }), S = C(), w = T(!1), P = { ref: n, onKeyDown(s) {
    s.key == "Tab" && (w.current = !0, S.requestAnimationFrame(() => {
      w.current = !1;
    }));
  }, onBlur(s) {
    let m = M(l);
    t.current instanceof HTMLElement && m.add(t.current);
    let p = s.relatedTarget;
    p instanceof HTMLElement && p.dataset.headlessuiFocusGuard !== "true" && (R(m, p) || (w.current ? E(t.current, $(D.current, { [v.Forwards]: () => d.Next, [v.Backwards]: () => d.Previous }) | d.WrapAround, { relativeTo: s.target }) : s.target instanceof HTMLElement && i(s.target)));
  } };
  return F.createElement(F.Fragment, null, !!(o & 4) && F.createElement(h, { as: "button", type: "button", "data-headlessui-focus-guard": !0, onFocus: L, features: y.Focusable }), q({ ourProps: P, theirProps: u, defaultTag: z, name: "FocusTrap" }), !!(o & 4) && F.createElement(h, { as: "button", type: "button", "data-headlessui-focus-guard": !0, onFocus: L, features: y.Focusable }));
}
let K = U(G), Fe = Object.assign(K, { features: A });
function Q(e = !0) {
  let r = T(b.slice());
  return g(([t], [n]) => {
    n === !0 && t === !1 && B(() => {
      r.current.splice(0);
    }), n === !1 && t === !0 && (r.current = b.slice());
  }, [e, b, r]), k(() => {
    var t;
    return (t = r.current.find((n) => n != null && n.isConnected)) != null ? t : null;
  });
}
function V({ ownerDocument: e }, r) {
  let t = Q(r);
  g(() => {
    r || (e == null ? void 0 : e.activeElement) === (e == null ? void 0 : e.body) && i(t());
  }, [r]), N(() => {
    r && i(t());
  });
}
function X({ ownerDocument: e, container: r, initialFocus: t }, n) {
  let a = T(null), l = H();
  return g(() => {
    if (!n)
      return;
    let o = r.current;
    o && B(() => {
      if (!l.current)
        return;
      let u = e == null ? void 0 : e.activeElement;
      if (t != null && t.current) {
        if ((t == null ? void 0 : t.current) === u) {
          a.current = u;
          return;
        }
      } else if (o.contains(u)) {
        a.current = u;
        return;
      }
      t != null && t.current ? i(t.current) : E(o, d.First) === x.Error && console.warn("There are no focusable elements inside the <FocusTrap />"), a.current = e == null ? void 0 : e.activeElement;
    });
  }, [n]), a;
}
function Y({ ownerDocument: e, container: r, containers: t, previousActiveElement: n }, a) {
  let l = H();
  I(e == null ? void 0 : e.defaultView, "focus", (o) => {
    if (!a || !l.current)
      return;
    let u = M(t);
    r.current instanceof HTMLElement && u.add(r.current);
    let f = n.current;
    if (!f)
      return;
    let c = o.target;
    c && c instanceof HTMLElement ? R(u, c) ? (n.current = c, i(c)) : (o.preventDefault(), o.stopPropagation(), i(f)) : i(n.current);
  }, !0);
}
function R(e, r) {
  for (let t of e)
    if (t.contains(r))
      return !0;
  return !1;
}
export {
  Fe as FocusTrap
};
