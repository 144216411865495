import k, { createContext as E, useRef as b, Fragment as L, useReducer as H, useMemo as v, useEffect as U, useContext as T } from "react";
import { useEvent as g } from "./designsystem-ui-react100.js";
import { useId as A } from "./designsystem-ui-react102.js";
import { useResolveButtonType as j } from "./designsystem-ui-react117.js";
import { useSyncRefs as R, optionalRef as Q } from "./designsystem-ui-react108.js";
import { OpenClosedProvider as V, State as $, useOpenClosed as W } from "./designsystem-ui-react109.js";
import { isDisabledReactIssue7711 as Y } from "./designsystem-ui-react112.js";
import { match as h } from "./designsystem-ui-react113.js";
import { getOwnerDocument as Z } from "./designsystem-ui-react118.js";
import { Features as F, forwardRefWithAs as w, render as O, useMergeRefsFn as N } from "./designsystem-ui-react114.js";
import { startTransition as q } from "./designsystem-ui-react119.js";
import { Keys as S } from "./designsystem-ui-react116.js";
var z = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(z || {}), G = ((e) => (e[e.ToggleDisclosure = 0] = "ToggleDisclosure", e[e.CloseDisclosure = 1] = "CloseDisclosure", e[e.SetButtonId = 2] = "SetButtonId", e[e.SetPanelId = 3] = "SetPanelId", e[e.LinkPanel = 4] = "LinkPanel", e[e.UnlinkPanel = 5] = "UnlinkPanel", e))(G || {});
let J = { 0: (e) => ({ ...e, disclosureState: h(e.disclosureState, { 0: 1, 1: 0 }) }), 1: (e) => e.disclosureState === 1 ? e : { ...e, disclosureState: 1 }, 4(e) {
  return e.linkedPanel === !0 ? e : { ...e, linkedPanel: !0 };
}, 5(e) {
  return e.linkedPanel === !1 ? e : { ...e, linkedPanel: !1 };
}, 2(e, t) {
  return e.buttonId === t.buttonId ? e : { ...e, buttonId: t.buttonId };
}, 3(e, t) {
  return e.panelId === t.panelId ? e : { ...e, panelId: t.panelId };
} }, B = E(null);
B.displayName = "DisclosureContext";
function x(e) {
  let t = T(B);
  if (t === null) {
    let o = new Error(`<${e} /> is missing a parent <Disclosure /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(o, x), o;
  }
  return t;
}
let K = E(null);
K.displayName = "DisclosureAPIContext";
function _(e) {
  let t = T(K);
  if (t === null) {
    let o = new Error(`<${e} /> is missing a parent <Disclosure /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(o, _), o;
  }
  return t;
}
let M = E(null);
M.displayName = "DisclosurePanelContext";
function X() {
  return T(M);
}
function ee(e, t) {
  return h(t.type, J, e, t);
}
let te = L;
function ne(e, t) {
  let { defaultOpen: o = !1, ...u } = e, f = b(null), n = R(t, Q((a) => {
    f.current = a;
  }, e.as === void 0 || e.as === L)), r = b(null), d = b(null), s = H(ee, { disclosureState: o ? 0 : 1, linkedPanel: !1, buttonRef: d, panelRef: r, buttonId: null, panelId: null }), [{ disclosureState: p, buttonId: i }, P] = s, c = g((a) => {
    P({ type: 1 });
    let I = Z(f);
    if (!I || !i)
      return;
    let l = a ? a instanceof HTMLElement ? a : a.current instanceof HTMLElement ? a.current : I.getElementById(i) : I.getElementById(i);
    l == null || l.focus();
  }), y = v(() => ({ close: c }), [c]), m = v(() => ({ open: p === 0, close: c }), [p, c]), C = { ref: n };
  return k.createElement(B.Provider, { value: s }, k.createElement(K.Provider, { value: y }, k.createElement(V, { value: h(p, { 0: $.Open, 1: $.Closed }) }, O({ ourProps: C, theirProps: u, slot: m, defaultTag: te, name: "Disclosure" }))));
}
let re = "button";
function le(e, t) {
  let o = A(), { id: u = `headlessui-disclosure-button-${o}`, ...f } = e, [n, r] = x("Disclosure.Button"), d = X(), s = d === null ? !1 : d === n.panelId, p = b(null), i = R(p, t, s ? null : n.buttonRef), P = N();
  U(() => {
    if (!s)
      return r({ type: 2, buttonId: u }), () => {
        r({ type: 2, buttonId: null });
      };
  }, [u, r, s]);
  let c = g((l) => {
    var D;
    if (s) {
      if (n.disclosureState === 1)
        return;
      switch (l.key) {
        case S.Space:
        case S.Enter:
          l.preventDefault(), l.stopPropagation(), r({ type: 0 }), (D = n.buttonRef.current) == null || D.focus();
          break;
      }
    } else
      switch (l.key) {
        case S.Space:
        case S.Enter:
          l.preventDefault(), l.stopPropagation(), r({ type: 0 });
          break;
      }
  }), y = g((l) => {
    switch (l.key) {
      case S.Space:
        l.preventDefault();
        break;
    }
  }), m = g((l) => {
    var D;
    Y(l.currentTarget) || e.disabled || (s ? (r({ type: 0 }), (D = n.buttonRef.current) == null || D.focus()) : r({ type: 0 }));
  }), C = v(() => ({ open: n.disclosureState === 0 }), [n]), a = j(e, p), I = s ? { ref: i, type: a, onKeyDown: c, onClick: m } : { ref: i, id: u, type: a, "aria-expanded": n.disclosureState === 0, "aria-controls": n.linkedPanel ? n.panelId : void 0, onKeyDown: c, onKeyUp: y, onClick: m };
  return O({ mergeRefs: P, ourProps: I, theirProps: f, slot: C, defaultTag: re, name: "Disclosure.Button" });
}
let oe = "div", se = F.RenderStrategy | F.Static;
function ae(e, t) {
  let o = A(), { id: u = `headlessui-disclosure-panel-${o}`, ...f } = e, [n, r] = x("Disclosure.Panel"), { close: d } = _("Disclosure.Panel"), s = N(), p = R(t, n.panelRef, (m) => {
    q(() => r({ type: m ? 4 : 5 }));
  });
  U(() => (r({ type: 3, panelId: u }), () => {
    r({ type: 3, panelId: null });
  }), [u, r]);
  let i = W(), P = i !== null ? (i & $.Open) === $.Open : n.disclosureState === 0, c = v(() => ({ open: n.disclosureState === 0, close: d }), [n, d]), y = { ref: p, id: u };
  return k.createElement(M.Provider, { value: n.panelId }, O({ mergeRefs: s, ourProps: y, theirProps: f, slot: c, defaultTag: oe, features: se, visible: P, name: "Disclosure.Panel" }));
}
let ue = w(ne), ie = w(le), ce = w(ae), ve = Object.assign(ue, { Button: ie, Panel: ce });
export {
  ve as Disclosure
};
