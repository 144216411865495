import { jsxs as x, jsx as y } from "react/jsx-runtime";
import { cn as t } from "./designsystem-ui-react46.js";
import { forwardRef as m } from "react";
import c from "./designsystem-ui-react66.js";
const f = m(
  ({
    children: b,
    variant: w = "primary",
    size: a = "regular",
    disabled: i = !1,
    type: d = "button",
    className: s,
    icon: r,
    iconPosition: n = "left",
    loading: e = !1,
    to: u,
    as: o = "button",
    ...l
  }, g) => {
    const p = {
      primary: t(
        // Colors
        "tw-text-button-primary-text tw-bg-button-primary-bg tw-fill-button-primary-text",
        // Border
        "tw-border-button-primary-bg",
        // Hover
        "hover:tw-bg-button-primary-bg-hover hover:tw-border-button-primary-bg-hover"
      ),
      secondary: t(
        // Colors
        "tw-text-button-secondary-text tw-bg-button-secondary-bg tw-fill-button-secondary-text",
        // Border
        "tw-border-button-secondary-border",
        // Hover
        "hover:tw-bg-button-secondary-bg-hover hover:tw-border-button-secondary-border"
      ),
      tertiary: t(
        // Colors
        "tw-text-button-tertiary-text tw-fill-tertiary-text tw-bg-button-tertiary-bg",
        // Border
        "tw-border-transparent",
        // Hover
        "hover:tw-bg-button-tertiary-bg-hover hover:tw-underline hover:tw-border-button-tertiary-bg-hover"
      ),
      destructive: t(
        // Colors
        "tw-text-ink-brand-inverted tw-fill-ink-brand-inverted tw-bg-button-danger-bg",
        // Border
        " tw-border-button-danger-bg",
        // Hover
        "hover:tw-bg-error-800 hover:tw-border-error-800"
      )
    }[w], v = {
      compact: "tw-text-sm tw-px-button-compact-padding-x tw-py-button-compact-padding-y [&>svg]:tw-w-[20px] [&>svg]:tw-h-[20px] [&>svg]:-tw-m-1",
      regular: "tw-text-base tw-px-button-regular-padding-x tw-py-button-regular-padding-y [&>svg]:tw-w-[20px] [&>svg]:tw-h-[20px] [&>svg]:-tw-m-1",
      full: "tw-text-base tw-w-full tw-px-button-regular-padding-x tw-py-button-regular-padding-y [&>svg]:tw-w-[20px] [&>svg]:tw-h-[20px] [&>svg]:-tw-m-1",
      iconOnly: "tw-p-3 [&>svg]:tw-w-[20px] [&>svg]:tw-h-[20px] [&>svg]:-tw-m-0.5"
    }[a];
    return /* @__PURE__ */ x(
      o,
      {
        ...o !== "button" ? { role: "button", href: u } : {},
        ref: g,
        type: d,
        ...l,
        className: t(
          p,
          v,
          // focus
          "focus-visible:tw-ring-2 focus-visible:tw-ring-information-600 focus-visible:tw-outline-none",
          // Disabled
          "disabled:tw-text-ink-disabled disabled:tw-border-button-disabled-bg disabled:tw-bg-button-disabled-bg disabled:tw-cursor-not-allowed disabled:tw-no-underline [&>svg]:disabled:tw-fill-ink-disabled",
          // Base
          "tw-cursor-pointer tw-leading-none tw-border-2 tw-no-underline tw-rounded-button tw-inline-flex tw-justify-center tw-items-center tw-gap-x-2",
          s
        ),
        disabled: i || e,
        children: [
          r && n === "left" && !e ? r : null,
          e ? /* @__PURE__ */ y(c, { className: "tw-animate-spin tw-fill-button-ink-disabled" }) : null,
          b,
          r && n === "right" && !e ? r : null
        ]
      }
    );
  }
);
f.displayName = "Button";
export {
  f as Button
};
