import { useEffect as s } from "react";
import { useLatestValue as m } from "./designsystem-ui-react132.js";
function i(e, o, t) {
  let r = m(o);
  s(() => {
    function n(u) {
      r.current(u);
    }
    return document.addEventListener(e, n, t), () => document.removeEventListener(e, n, t);
  }, [e, t]);
}
export {
  i as useDocumentEvent
};
