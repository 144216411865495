import * as t from "react";
import { env as f } from "./designsystem-ui-react138.js";
function r() {
  let n = typeof document > "u";
  return "useSyncExternalStore" in t ? ((e) => e.useSyncExternalStore)(t)(() => () => {
  }, () => !1, () => !n) : !1;
}
function u() {
  let n = r(), [e, o] = t.useState(f.isHandoffComplete);
  return e && f.isHandoffComplete === !1 && o(!1), t.useEffect(() => {
    e !== !0 && o(!0);
  }, [e]), t.useEffect(() => f.handoff(), []), n ? !1 : e;
}
export {
  u as useServerHandoffComplete
};
