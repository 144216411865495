import { useState as r, useRef as o, useEffect as M } from "react";
import w from "./designsystem-ui-react75.js";
function G() {
  const [u, d] = r(!1), [g, T] = r(!1), [k, I] = r(0), [m, c] = r(0), [y, S] = r(!0), [p, v] = r(0), [P, h] = r("Laster..."), [L, i] = r(!1), l = o(!1), t = o(null), n = o(), a = o(), R = () => {
    n.current && clearInterval(n.current), n.current = setInterval(() => {
      if (t.current && !L) {
        const e = t.current.getCurrentTime();
        c(e);
      }
    }, 20);
  }, f = () => {
    n.current && clearInterval(n.current);
  }, V = () => {
    a.current && clearInterval(a.current), a.current = setInterval(() => {
      if (t.current) {
        const e = t.current.getVideoLoadedFraction();
        v(e);
      }
    }, 100);
  }, x = (e) => {
    t.current = e.target, S(!1), V();
    const s = e.target.getDuration();
    I(s);
    const B = Math.max(1, Math.floor(s / 60));
    h(`Spill av filmen • ${B} min`);
  }, C = (e) => {
    const s = e.data === w.PlayerState.PLAYING;
    d(s), s ? (T(!0), R()) : f();
  }, D = (e) => {
    t.current && (c(e), t.current.seekTo(e, !0));
  }, F = () => {
    i(!0), l.current = u, t.current && t.current.pauseVideo();
  }, Y = (e) => {
    t.current && (c(e), t.current.seekTo(e, !0), t.current.pauseVideo());
  }, b = (e) => {
    t.current && (c(e), t.current.seekTo(e, !0), l.current && t.current.playVideo(), i(!1));
  };
  return M(() => () => {
    f(), a.current && clearInterval(a.current);
  }, []), {
    playerRef: t,
    isPlaying: u,
    hasStarted: g,
    duration: k,
    currentTime: m,
    isLoading: y,
    loadedFraction: p,
    playButtonText: P,
    handleReady: x,
    handleStateChange: C,
    seekTo: D,
    startSeeking: F,
    seekWhileDragging: Y,
    endSeeking: b
  };
}
export {
  G as useYouTubePlayer
};
