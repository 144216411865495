import { jsx as r, Fragment as b, jsxs as s } from "react/jsx-runtime";
import { cn as a } from "./designsystem-ui-react46.js";
import { Link as h } from "./designsystem-ui-react21.js";
import v from "./designsystem-ui-react55.js";
import o from "./designsystem-ui-react60.js";
import k from "./designsystem-ui-react61.js";
import S from "./designsystem-ui-react62.js";
import y from "./designsystem-ui-react63.js";
import C from "./designsystem-ui-react64.js";
function c({ iconVariant: l, CustomIcon: e }) {
  const t = "tw-w-7 tw-flex-shrink-0";
  if (e)
    return /* @__PURE__ */ r(e, { className: t });
  switch (l) {
    case "information":
      return /* @__PURE__ */ r(o, { className: a(t, "tw-fill-information-600") });
    case "error":
      return /* @__PURE__ */ r(C, { className: a(t, "tw-fill-error-600") });
    case "warning":
      return /* @__PURE__ */ r(y, { className: a(t, "tw-fill-warning-600") });
    case "success":
      return /* @__PURE__ */ r(S, { className: a(t, "tw-fill-success-600") });
    case "tip":
      return /* @__PURE__ */ r(k, { className: t });
    default:
      return /* @__PURE__ */ r(o, { className: t });
  }
}
function A({
  children: l,
  title: e,
  variant: t = "information",
  ariaLive: w = "polite",
  customIcon: n,
  fullWidth: m = !1,
  show: f = !0,
  showCloseButton: u = !0,
  onClose: d,
  maxWidth: g,
  cta: i,
  className: p,
  ctaText: x
}) {
  const N = {
    information: "tw-bg-surface-information",
    warning: "tw-bg-surface-warning",
    error: "tw-bg-surface-error",
    success: "tw-bg-surface-success",
    tip: "tw-bg-surface-tip tw-rounded-lg"
  }[t];
  return /* @__PURE__ */ r(b, { children: f ? /* @__PURE__ */ r(
    "div",
    {
      "aria-live": w,
      className: a(
        "tw-p-4 tw-flex tw-@container tw-rounded",
        N,
        m ? "tw-max-w-none" : "tw-max-w-[500px]",
        p
      ),
      children: /* @__PURE__ */ r("div", { className: "tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-4", style: { maxWidth: g }, children: /* @__PURE__ */ s("div", { className: "tw-flex tw-w-full tw-gap-x-2 tw-items-start", children: [
        n ? /* @__PURE__ */ r(c, { CustomIcon: n }) : /* @__PURE__ */ r(c, { iconVariant: t }),
        /* @__PURE__ */ s("div", { className: "tw-flex tw-flex-col tw-gap-y-2", children: [
          e ? /* @__PURE__ */ r("p", { className: "tw-mb-0 tw-font-semibold tw-text-regular tw-leading-normal @sm:tw-text-lg", children: e }) : null,
          /* @__PURE__ */ r("div", { className: "tw-leading-normal tw-text-base", children: l }),
          i ? /* @__PURE__ */ r("div", { children: /* @__PURE__ */ r(h, { href: i, children: x }) }) : null
        ] }),
        u ? /* @__PURE__ */ r(
          "button",
          {
            "aria-label": "Lukk",
            className: "tw-p-0 tw-ml-auto tw-bg-transparent tw-border-none tw-cursor-pointer tw-fill-ink-brand-default",
            onClick: d,
            type: "button",
            children: /* @__PURE__ */ r(v, {})
          }
        ) : null
      ] }) })
    }
  ) : null });
}
A.displayName = "Alert";
export {
  A as Alert
};
