import { store } from "@risingstack/react-easy-state";
import { getISODay, isAfter, isSameDay, parseISO } from "date-fns";
import { has, isEmpty, max } from "lodash-es";
import defaultTo from "lodash-es/defaultTo";
import isNil from "lodash-es/isNil";
import isObject from "lodash-es/isObject";
import isString from "lodash-es/isString";
import isUndefined from "lodash-es/isUndefined";
import omit from "lodash-es/omit";
import some from "lodash-es/some";

import { apiClient } from "../../common/apiClient";
import { DayFlags } from "../../common/types/calendarTypes";
import { DeliveryDate, DeliveryDateResponse, OrderTypeWithDeliveryDates, PickupData } from "../../common/types/deliveryTypes";
import { FEATURE_NAME } from "../../common/types/featureTypes";
import { ORDERTYPE } from "../../common/types/productOrderTypes";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsLoading,
   setAsWaitingForData
} from "../../common/utils/asyncDataUtils";
import { removeUnwantedDates } from "../../common/utils/dateUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import loginState from "../auth/loginState";
import cartStore from "../cart/cartStore";
import featuresStore from "../features/featuresStore";
import productStore from "../product/productStore";
import toastStore from "../toastStore";
import uiStore from "../uiStore";
import {
   MORE_THAN_SIXTY_MINUTES,
   parse2022DeliveryDates,
   parse2022DeliveryDatesForSubscription,
   parse2022PickupDates,
   SIXTY_MINUTES
} from "./deliveryDateUtils";

type DeliveryDatesByOrderType = {
   [type in ORDERTYPE]?: DeliveryDate;
};

type DeliveryDatesStore = {
   nowTimestamp: number;
   deliveryDates: AsyncData<OrderTypeWithDeliveryDates>;
   holidays: AsyncData<Date[]>;
   pickupLocation: AsyncData<Omit<PickupData, "pickupAt"> | null>;
   cancelledDates: Date[];
   currentDelivery: DeliveryDatesByOrderType;
   changingDeliveryDate: boolean;

   fetchDeliveryDates: (customerNumber: string) => Promise<void>;
   fetchHolidays: () => Promise<void>;
   clearDeliveryDates: () => void;

   getCurrentDelivery: (type?: ORDERTYPE) => DeliveryDate | null;
   getDeliveryForDate: (date: Date, orderType: ORDERTYPE) => DeliveryDate | undefined;
   changeDeliveryDate: (date: Date, orderType?: ORDERTYPE) => Promise<void>;

   getDayFlags: (date: Date) => DayFlags;
   getNextMatchingDeliveryDate: (requiredWeekDay: number | string | null, orderType?: ORDERTYPE) => DeliveryDate | undefined;
   getAllDeliveriesForOrderType: (orderType: ORDERTYPE) => DeliveryDate[];
   getNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => DeliveryDate | null;
   selectNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => void;
   getDeadlineForDelivery: (queryDate: Date, orderType: ORDERTYPE) => Date | undefined;
   removeExpiredDeliveryDates: () => void;
   isDeliveryDate: (date: Date, orderType?: ORDERTYPE) => boolean;
   isCancelledDate: (date: Date) => boolean;
   isHoliday: (date: Date) => boolean;
   getAvailableOrderTypes: () => ORDERTYPE[] | null;
   getExpiration: (deliveryDate: DeliveryDate) => number;
   getCurrentDeliveryExpiration: (type?: ORDERTYPE) => number | null;
};

const deliveryDatesStore: DeliveryDatesStore = store({
   deliveryDates: initializeWithDefaultData({}),
   holidays: initializeWithDefaultData([]),
   pickupLocation: initializeWithDefaultData(null),
   cancelledDates: [],
   currentDelivery: {},
   changingDeliveryDate: false,
   nowTimestamp: new Date().getTime(),

   fetchDeliveryDates: async (customerNumber: string) => {
      if (!featuresStore.hasCustomerFeature(FEATURE_NAME.createOrderAvailable)) {
         console.log("Create ordre feature not available, aborting delivery dates fetch.");
         return;
      }
      setAsWaitingForData(deliveryDatesStore.pickupLocation);
      setAsWaitingForData(deliveryDatesStore.deliveryDates);
      const token = authStore.getSessionToken();
      let deliveries: OrderTypeWithDeliveryDates = {};

      try {
         const resp: DeliveryDateResponse = await apiClient(
            `${process.env.API_HOST}/api/deliverydates_2022/${customerNumber}`,
            token
         )
            .query({ company: theme.m3CompanyNumber })
            .get()
            .json();

         if (has(resp, "deliveryDates")) {
            deliveries[ORDERTYPE.WEB] = parse2022DeliveryDates(resp);
            deliveries[ORDERTYPE.WAS] = parse2022DeliveryDatesForSubscription(resp);
         }
         if (has(resp, "cancelledDeliveryDates")) {
            deliveryDatesStore.cancelledDates = resp.cancelledDeliveryDates.map((dateStr) => parseISO(dateStr));
         } else {
            deliveryDatesStore.cancelledDates = [];
         }
         if (has(resp, "extraDeliveryDates")) {
            const extraDates = resp.extraDeliveryDates.map((dateStr) => new Date(dateStr));
            const webDeliveries: DeliveryDate[] | undefined = deliveries[ORDERTYPE.WEB];
            webDeliveries?.forEach((delivery) => {
               if (extraDates.some((extraDate) => isSameDay(extraDate, delivery.date))) {
                  delivery.isExtra = true;
               }
            });

            console.log({ extra: resp.extraDeliveryDates });
         }
         deliveries = removeUnwantedDates(deliveries, [ORDERTYPE.WEB, ORDERTYPE.WAS]);
      } catch (err: unknown) {
         console.log("User has no delivery dates.", "" + err);
      }

      if (featuresStore.hasCustomerFeature(FEATURE_NAME.pickupOrderAvailable)) {
         try {
            const pickupData: PickupData = await apiClient(
               `${process.env.API_HOST}/api/deliverydates_2022/pickupdates/${theme.m3CompanyNumber}/${customerNumber}`,
               token
            )
               .get()
               .json();

            if (!isNil(pickupData)) {
               deliveries[ORDERTYPE.HPN] = parse2022PickupDates(pickupData);
            }

            setAsDataAvailable(deliveryDatesStore.pickupLocation, omit(pickupData, "pickupAt"));
         } catch (err: unknown) {
            setAsErrorOccured(deliveryDatesStore.pickupLocation, "" + err);
            console.log("User has no pick up dates.", "" + err);
         }
      } else {
         setAsErrorOccured(
            deliveryDatesStore.pickupLocation,
            "No pickup location when user don't have 'pickupOrderAvailable'-feature"
         );
      }

      if (isEmpty(deliveryDatesStore.deliveryDates)) {
         setAsErrorOccured(deliveryDatesStore.deliveryDates, "User has neither delivery dates nor pickup dates.");
      } else {
         setAsDataAvailable(deliveryDatesStore.deliveryDates, deliveries);
      }
   },

   fetchHolidays: async () => {
      setAsLoading(deliveryDatesStore.holidays);

      const holidayUrl = `${process.env.STATICDATA_HOST}/holidays.${process.env.ENV_NAME}_${uiStore.dataVersion}.json`;
      console.log("Fetching holidays from " + holidayUrl);

      return apiClient(holidayUrl)
         .get()
         .json((holidays) => {
            setAsDataAvailable(deliveryDatesStore.holidays, holidays.map(parseISO));
         })
         .catch((err) => {
            setAsErrorOccured(deliveryDatesStore.holidays, err);
         });
   },

   getCurrentDelivery: (type?: ORDERTYPE) => {
      const orderType = type || cartStore.orderType;
      return deliveryDatesStore.currentDelivery?.[orderType] || null;
   },
   getExpiration: (deliveryDate: DeliveryDate) => {
      let msToDeadline = Math.floor((deliveryDate.deadline.getTime() - deliveryDatesStore.nowTimestamp) / 1000) * 1000;
      if (msToDeadline > SIXTY_MINUTES) {
         msToDeadline = MORE_THAN_SIXTY_MINUTES;
      }
      return max([msToDeadline, 0]) || 0;
   },
   getCurrentDeliveryExpiration: (type?: ORDERTYPE): number | null => {
      const orderType = type || cartStore.orderType;
      const currentDelivery = deliveryDatesStore.getCurrentDelivery(orderType);
      if (currentDelivery?.deadline) {
         return deliveryDatesStore.getExpiration(currentDelivery);
      }
      return null;
   },

   clearDeliveryDates: () => {
      deliveryDatesStore.currentDelivery = {};
      deliveryDatesStore.deliveryDates = initializeWithDefaultData({});
   },

   removeExpiredDeliveryDates: () => {
      deliveryDatesStore.deliveryDates.data = Object.keys(deliveryDatesStore.deliveryDates.data)
         .map((orderType) => {
            const deliveryDates = deliveryDatesStore.deliveryDates.data[orderType as ORDERTYPE];
            return {
               orderType,
               deliveryDates: deliveryDates?.filter((deliveryDate) => deliveryDatesStore.getExpiration(deliveryDate))
            };
         })
         .filter((d) => d.deliveryDates?.length)
         .reduce((obj, next) => ({ ...obj, [next.orderType]: next.deliveryDates }), {});
   },

   /**
    * Find first delivery date for the ordertype where delivery date and order deadline is in the future.
    * If weekday is given, make sure that the delivery date also matches the given day of week.
    *
    * @param {string | number | null} requiredWeekDay - Weekday number (1-7) or null
    * @param {ORDERTYPE} orderType - Order type to find delivery date for
    */
   getNextMatchingDeliveryDate: (requiredWeekDay = null, orderType) => {
      const deliveryDates = deliveryDatesStore.getAllDeliveriesForOrderType(defaultTo(orderType, cartStore.orderType));
      const now = new Date();
      const weekdayNumber = isString(requiredWeekDay) ? parseInt(requiredWeekDay) : requiredWeekDay;

      return deliveryDates.find(
         (dd) =>
            isAfter(dd.date, now) &&
            isAfter(dd.deadline, now) &&
            (requiredWeekDay === null || getISODay(dd.date) === weekdayNumber)
      );
   },

   getAllDeliveriesForOrderType: (orderType: ORDERTYPE) => {
      return defaultTo(deliveryDatesStore.deliveryDates.data[orderType], []);
   },

   getNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => {
      const now = deliveryDatesStore.nowTimestamp;
      const deliveriesForOrderType = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      if (deliveriesForOrderType.length === 0) {
         return null;
      }
      return deliveriesForOrderType.find((deliveryDate) => deliveryDatesStore.getExpiration(deliveryDate)) || null;
   },

   selectNextDeliveryDateForOrderType: (orderType: ORDERTYPE) => {
      const nextDelivery = deliveryDatesStore.getNextDeliveryDateForOrderType(orderType);
      const newDelivery = isObject(nextDelivery) ? { ...nextDelivery } : null;
      if (newDelivery) {
         if (authStore.isLoggedIn()) {
            deliveryDatesStore.changeDeliveryDate(newDelivery.date, orderType);
         } else {
            deliveryDatesStore.currentDelivery[orderType] = newDelivery;
         }
      }
   },

   changeDeliveryDate: async (newDate, type: ORDERTYPE = cartStore.orderType) => {
      deliveryDatesStore.changingDeliveryDate = true;

      const foundDate = deliveryDatesStore.getDeliveryForDate(newDate, type);
      if (isUndefined(foundDate)) {
         deliveryDatesStore.changingDeliveryDate = false;
         toastStore.addError("Feil", "Kunne ikke endre leveringsdato.", {
            context: "delivery_dates",
            text: "no_delivery_day_found_error"
         });
         console.warn("Unable to find date selected in calendar, bug?");
         throw "Unable to find date selected in calendar";
      }

      const previousInterval = defaultTo(deliveryDatesStore.getCurrentDelivery(type)?.interval, 7);

      deliveryDatesStore.currentDelivery[type] = {
         ...foundDate
      };

      if (type === ORDERTYPE.WAS) {
         const currentWASDelivery = deliveryDatesStore.currentDelivery[ORDERTYPE.WAS];
         if (currentWASDelivery) {
            currentWASDelivery.interval = previousInterval;
         }
      }

      const currentDelivery = deliveryDatesStore.getCurrentDelivery(type);
      console.log("Selected delivery", currentDelivery);

      if (isNil(currentDelivery)) {
         toastStore.addError("Feil", "Kunne ikke endre leveringsdato.", {
            context: "delivery_dates",
            text: "no_current_delivery_day_error"
         });
         deliveryDatesStore.changingDeliveryDate = false;
         return;
      }

      if (loginState.is("LOGGED_IN")) {
         await productStore.getAssortment(authStore.currentCompany, currentDelivery.date, cartStore.orderType);
      }
      cartStore.lostSales = [];
      deliveryDatesStore.changingDeliveryDate = false;
   },

   getDeliveryForDate: (date, orderType) => {
      const deliveries = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      return deliveries.find((delivery) => isSameDay(delivery.date, date));
   },

   getDeadlineForDelivery: (queryDate, orderType) => {
      const delivery = deliveryDatesStore.getDeliveryForDate(queryDate, orderType);
      return delivery?.deadline;
   },

   getDayFlags: (date: Date): DayFlags => {
      const holiday = deliveryDatesStore.isHoliday(date);
      const cancelled = deliveryDatesStore.isCancelledDate(date);
      const selectable = deliveryDatesStore.isDeliveryDate(date, cartStore.orderType) && !cancelled;
      const dayFlags = {
         holiday,
         cancelled,
         disabled: !selectable,
         delivery: selectable,
         selectable
      };
      return dayFlags;
   },

   isDeliveryDate: (queryDate, orderType = cartStore.orderType) => {
      const deliveries = deliveryDatesStore.getAllDeliveriesForOrderType(orderType);
      return some(deliveries, (delivery) => isSameDay(delivery.date, queryDate) && deliveryDatesStore.getExpiration(delivery));
   },

   isCancelledDate: (queryDate) => {
      return some(deliveryDatesStore.cancelledDates, (cancelledDate) => isSameDay(cancelledDate, queryDate));
   },

   isHoliday: (queryDate) => {
      return some(deliveryDatesStore.holidays.data, (holiday) => isSameDay(holiday, queryDate));
   },
   getAvailableOrderTypes: () => {
      if (!loginState.is("DELIVERY_DATES_LOADING")) {
         return null;
      }

      return Object.keys(deliveryDatesStore.deliveryDates.data) as ORDERTYPE[];
   }
} satisfies DeliveryDatesStore);

const intervalId = setInterval(() => {
   deliveryDatesStore.nowTimestamp = new Date().getTime();
}, 1000);

if (window) {
   window.addEventListener("onbeforeunload", () => {
      clearInterval(intervalId);
   });
}

export default deliveryDatesStore;
