import { jsxs as w, jsx as t } from "react/jsx-runtime";
import { useId as c, Fragment as u } from "react";
import { cn as e } from "./designsystem-ui-react46.js";
import { Switch as p } from "./designsystem-ui-react84.js";
function m({
  isEnabled: o,
  label: a,
  onChange: i,
  isLabelHidden: l = !1,
  isDisabled: r,
  className: d
}) {
  const n = c();
  return /* @__PURE__ */ w("div", { className: e("tw-flex tw-gap-1 tw-items-center", d), children: [
    /* @__PURE__ */ t(p, { as: u, checked: o, disabled: r, onChange: i, children: ({ checked: s }) => /* @__PURE__ */ w(
      "button",
      {
        className: e(
          // button css reset
          "tw-m-0 tw-appearance-none tw-font-inherit tw-cursor-pointer",
          "tw-group tw-relative tw-flex tw-items-center tw-rounded-full tw-h-6 tw-p-1 tw-w-12",
          // animation
          "tw-transition-colors tw-duration-200 tw-ease-in-out",
          // focus
          "focus:tw-outline-none focus-visible:tw-ring data-[checked]:tw-bg-primary",
          "tw-border-base-transparent",
          s ? "tw-bg-surface-action hover:tw-bg-base-600" : "tw-bg-base-400 hover:tw-bg-base-600",
          // disabled state
          "data-[disabled]:tw-cursor-not-allowed"
        ),
        id: n,
        type: "button",
        children: [
          /* @__PURE__ */ t("span", { className: "tw-sr-only", children: typeof a == "string" ? a : "Switch" }),
          /* @__PURE__ */ t(
            "span",
            {
              className: e(
                "tw-pointer-events-none tw-bg-base-0 tw-inline-block tw-size-5 tw-rounded-full tw-ring-0 tw-shadow tw-transition tw-duration-200 tw-ease-in-out group-data-[checked]:tw-translate-x-6",
                // disabled state
                "group-data-[disabled]:tw-bg-base-300",
                s ? "tw-translate-x-[22px] md:group-hover:tw-translate-x-[20px]" : "-tw-translate-x-0.5 md:group-hover:tw-translate-x-0",
                r ? "tw-bg-base-300 tw-cursor-not-allowed" : ""
              )
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ t("label", { className: e("tw-text-sm tw-text-ink-brand-default", l && "tw-sr-only"), htmlFor: n, children: a })
  ] });
}
m.displayName = "Switch";
export {
  m as Switch
};
