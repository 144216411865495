import o from "react";
import { env as t } from "./designsystem-ui-react138.js";
import { useIsoMorphicEffect as n } from "./designsystem-ui-react131.js";
import { useServerHandoffComplete as s } from "./designsystem-ui-react107.js";
var r;
let i = (r = o.useId) != null ? r : function() {
  let l = s(), [e, u] = o.useState(l ? () => t.nextId() : null);
  return n(() => {
    e === null && u(t.nextId());
  }, [e]), e != null ? "" + e : void 0;
};
export {
  i as useId
};
