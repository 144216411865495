import { view } from "@risingstack/react-easy-state";

import useMediaQuery from "../common/useMediaQuery";
import { mobileBreakpoint } from "../common/utils";

import theme from "../themes/theme";

type StoreLogoProps = {
   placement: "searchbar" | "searchbarMobile" | "footer";
} & React.HTMLAttributes<HTMLImageElement>;

const StoreLogoComponent = ({ placement, ...rest }: StoreLogoProps) => {
   if (!theme.storeLogo[placement]) {
      return;
   }
   const width = theme.storeLogo[placement].mobileWidth
      ? useMediaQuery(mobileBreakpoint)
         ? theme.storeLogo[placement].mobileWidth
         : theme.storeLogo[placement].width
      : theme.storeLogo[placement].width;

   return <img role="button" alt={"Logo"} width={width} src={theme.storeLogo[placement].url} tabIndex={0} {...rest} />;
};

export default view(StoreLogoComponent);
