import { view } from "@risingstack/react-easy-state";

import contentStore from "../stores/cms/contentStore";

import AlertMessage from "./AlertMessage";

const TopBarWarnings = () => {
   const warnings = contentStore.getCurrentWarnings();

   const filteredWarnings = warnings.filter((c) => c.warningtype?.includes("Toppen av alle sider"));

   if (filteredWarnings.length === 0) {
      return null;
   }
   return (
      <aside className="print:tw-hidden">
         {filteredWarnings.map((warning) => (
            <AlertMessage key={warning.internalName} warning={warning} className="md:[&>div]:tw-px-12" />
         ))}
      </aside>
   );
};

export default view(TopBarWarnings);
