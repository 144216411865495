import { __module as b } from "./designsystem-ui-react170.js";
import { b as R } from "./designsystem-ui-react171.js";
import { f as U } from "./designsystem-ui-react172.js";
import { e as z } from "./designsystem-ui-react173.js";
import { F as B } from "./designsystem-ui-react174.js";
(function(A, S) {
  Object.defineProperty(S, "__esModule", {
    value: !0
  });
  var M = R, T = d(M), w = U, F = d(w), Y = z, L = d(Y), O = B, E = d(O);
  function d(t) {
    return t && t.__esModule ? t : { default: t };
  }
  var q = (0, T.default)("youtube-player"), h = {};
  h.proxyEvents = function(t) {
    var p = {}, s = function(l) {
      var e = "on" + l.slice(0, 1).toUpperCase() + l.slice(1);
      p[e] = function(n) {
        q('event "%s"', e, n), t.trigger(l, n);
      };
    }, v = !0, u = !1, c = void 0;
    try {
      for (var i = L.default[Symbol.iterator](), f; !(v = (f = i.next()).done); v = !0) {
        var g = f.value;
        s(g);
      }
    } catch (y) {
      u = !0, c = y;
    } finally {
      try {
        !v && i.return && i.return();
      } finally {
        if (u)
          throw c;
      }
    }
    return p;
  }, h.promisifyPlayer = function(t) {
    var p = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !1, s = {}, v = function(e) {
      p && E.default[e] ? s[e] = function() {
        for (var n = arguments.length, m = Array(n), a = 0; a < n; a++)
          m[a] = arguments[a];
        return t.then(function(r) {
          var o = E.default[e], I = r.getPlayerState(), x = r[e].apply(r, m);
          return o.stateChangeRequired || // eslint-disable-next-line no-extra-parens
          Array.isArray(o.acceptableStates) && o.acceptableStates.indexOf(I) === -1 ? new Promise(function(_) {
            var N = function C() {
              var D = r.getPlayerState(), P = void 0;
              typeof o.timeout == "number" && (P = setTimeout(function() {
                r.removeEventListener("onStateChange", C), _();
              }, o.timeout)), Array.isArray(o.acceptableStates) && o.acceptableStates.indexOf(D) !== -1 && (r.removeEventListener("onStateChange", C), clearTimeout(P), _());
            };
            r.addEventListener("onStateChange", N);
          }).then(function() {
            return x;
          }) : x;
        });
      } : s[e] = function() {
        for (var n = arguments.length, m = Array(n), a = 0; a < n; a++)
          m[a] = arguments[a];
        return t.then(function(r) {
          return r[e].apply(r, m);
        });
      };
    }, u = !0, c = !1, i = void 0;
    try {
      for (var f = F.default[Symbol.iterator](), g; !(u = (g = f.next()).done); u = !0) {
        var y = g.value;
        v(y);
      }
    } catch (l) {
      c = !0, i = l;
    } finally {
      try {
        !u && f.return && f.return();
      } finally {
        if (c)
          throw i;
      }
    }
    return s;
  }, S.default = h, A.exports = S.default;
})(b, b.exports);
var V = b.exports;
export {
  V as Y
};
