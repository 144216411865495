import { store } from "@risingstack/react-easy-state";
import isNil from "lodash-es/isNil";
import { WretchError } from "wretch";

import { apiClient } from "../../common/apiClient";
import { DeliveryWindowData } from "../../common/types/deliveryTypes";
import { translateWeekday } from "../../common/utils";
import {
   AsyncData,
   initializeWithDefaultData,
   setAsDataAvailable,
   setAsErrorOccured,
   setAsLoading
} from "../../common/utils/asyncDataUtils";
import { englishDayToDay } from "../../common/utils/dateUtils";

import authStore from "../auth/authStore";

type DeliveryWindow = {
   time: string;
   weekday: string;
};

type DeliveryWindowResponse = {
   customerNumber: string;
   deliveries: Array<{
      day: string;
      deliveryWindows: Array<{
         start: string;
         stop: string;
      }>;
   }>;
};

type DeliveryWindowStore = {
   windows: AsyncData<DeliveryWindow[]>;
   windowsTableData: AsyncData<DeliveryWindowData[]>;
   fetchWindows: () => Promise<void>;
   reset: () => void;
};

const deliveryWindowStore: DeliveryWindowStore = store({
   windows: initializeWithDefaultData([]),
   windowsTableData: initializeWithDefaultData([]),
   reset: () => {
      deliveryWindowStore.windows = initializeWithDefaultData([]);
      deliveryWindowStore.windowsTableData = initializeWithDefaultData([]);
   },
   fetchWindows: async () => {
      if (!authStore.isLoggedIn() || isNil(authStore.currentCompany)) {
         console.warn("Fetch delivery windows called without being logged in or without a current company");
         return;
      }

      const transitionedSuccessfully = setAsLoading(deliveryWindowStore.windows);
      if (!transitionedSuccessfully) {
         console.log("Already fetching delivery windows... aborting!");
         return;
      }
      setAsLoading(deliveryWindowStore.windowsTableData);

      const url = `${process.env.API_HOST}/api/deliverywindow/${authStore.currentCompany}/delivery-windows`;
      //const resp: DeliveryWindowResponse = await;
      apiClient(url, authStore.getSessionToken())
         .get()

         .notFound(() => {
            console.log("No delivery windows found for customer");
            setAsDataAvailable(deliveryWindowStore.windows, []);
            setAsDataAvailable(deliveryWindowStore.windowsTableData, []);
         })

         .json((resp: DeliveryWindowResponse) => {
            const storeData = resp.deliveries.map((delivery) => ({
               time: delivery.deliveryWindows.map((window) => `${window.start} - ${window.stop}`).join(", "),
               weekday: translateWeekday(delivery.day)
            }));

            const windowsData = resp.deliveries
               .map((delivery) => ({
                  day: englishDayToDay(delivery.day),
                  periods: delivery.deliveryWindows
               }))
               .filter((windowsData) => typeof windowsData.day === "number");

            setAsDataAvailable(deliveryWindowStore.windows, storeData);
            setAsDataAvailable(deliveryWindowStore.windowsTableData, windowsData);
         })

         .catch((err: WretchError) => {
            console.warn("Failed to fetch delivery windows", err);
            setAsErrorOccured(deliveryWindowStore.windows, "" + err);
            setAsErrorOccured(deliveryWindowStore.windowsTableData, `${err}`);
         });
   }
});

export default deliveryWindowStore;
