// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import desktopLogoUrl from "url:/src/img/themes/isdalen/Isdalen_premium_ICECREAM_CMYK_positiv.svg";
// @ts-ignore - needed because TypeScript does not support loading SVGs as images
import mobileLogoUrl from "url:/src/img/themes/isdalen/Isdalen_premium_ICECREAM_negativ_positiv.svg";
import includes from "lodash-es/includes";
import isArray from "lodash-es/isArray";

import { CustomerFeature, FEATURE_NAME } from "../common/types/featureTypes";
import { Invoice } from "../common/types/invoiceTypes";
import { SupportedLocaleType } from "../common/types/languageTypes";
import { Product } from "../common/types/productTypes";
import { SiteConfig } from "../common/types/themeTypes";

const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV ?? "";
const ALGOLIA_APPID = "CV5266SV9V";
const ALGOLIA_SEARCHKEY = "6e0ed776d4e102db935498659894446c";
const algoliaEnvironment = process.env.ENV_NAME === "prod" ? "prod" : "preprod";

const enhanceIsdalenProduct = (product: Product): Product => {
   // Additional mappings based on product name or external category reference
   if (product.size === "-") {
      product.size = undefined;
   }

   if (product.category?.externalReference === "I0041" || product.category?.externalReference === "I0061") {
      product.type = "Tilbehør";
   }

   if (product.category?.externalReference === "I0140") {
      product.type = "Frukt";
   }

   if (product.size === null && includes(product.name, "5L")) {
      product.size = "5 liter";
   }

   if ((product.type === null && includes(product.name, "Eskimo")) || includes(product.name, "Shake")) {
      product.type = "Iskrem";
   }

   // Product data is published with a period as decimal, this in not correct in Norwegian.
   if (isArray(product.nutritions)) {
      product.nutritions.forEach((nutrition) => {
         nutrition.value = nutrition.value.replace(/\./g, ",");
      });
   }

   return product;
};

const isdalenSiteConfig: SiteConfig = {
   storeId: "isdalen",
   storeCompanyName: "Isdalen",
   orderSupportEmail: "post@isdalen.no",
   baseUrl: "",
   siteName: "Isdalen Handel",
   m3CompanyNumber: "500",
   m3DivisionNumber: "500",
   productDropdownEnabled: false,
   showComparisonPrice: false,
   storeLogo: {
      searchbar: {
         url: desktopLogoUrl.toString(),
         width: 150
      },
      searchbarMobile: {
         url: mobileLogoUrl.toString(),
         width: 80
      },
      footer: {
         url: mobileLogoUrl.toString(),
         width: 140,
         mobileWidth: 90
      }
   },
   faqSlug: "/kundeservice/ofte-stilte-sporsmal",
   filterAttributes: () => ({
      type: "Type",
      size: "Størrelse"
   }),
   papirflyApsCustomerId: "12590",
   papirflyApsImagePrefix: "isd-",
   enhanceProduct: enhanceIsdalenProduct,
   filterRefreshOnChange: true,
   tipApiPrefix: "is-",
   fetchCustomerFeatures: async (customerNumber: string) => {
      return Promise.resolve([
         { name: FEATURE_NAME.subscriptionOrderAvailable, description: "Makes subscriptions available" },
         { name: FEATURE_NAME.invoicePaymentAvailable, description: "Makes invoice overview available" },
         { name: FEATURE_NAME.createOrderAvailable, description: "Allows customer to place orders" }
      ] satisfies CustomerFeature[]);
   },
   invoiceTooltipText: (invoice: Invoice) => "",
   deliveryFee: {
      enabled: false
   },
   productAvailability: {
      enabled: true
   },
   newCustomerForm: "https://xml.isdalenhandel.no/bli-kunde",
   deliveryNotes: {
      visible: true
   },
   searchConfig: {
      algoliaConfig: {
         appId: ALGOLIA_APPID,
         searchApiKey: ALGOLIA_SEARCHKEY
      },
      searchDomains: [
         {
            type: "product",
            name: "produkter",
            displayName: (locale: SupportedLocaleType) => (locale === "en-US" ? "Products" : "Produkter"),
            homePath: (_locale) => "/produkter.html",
            indexName: `products_${algoliaEnvironment}`,
            linkText: (_locale) => "Se alle produkter"
         },
         {
            type: "v2_article",
            name: "inspirasjon",
            displayName: (locale: SupportedLocaleType) => "Nyheter",
            homePath: () => "/nyheter",
            indexName: `contentful_${CONTENTFUL_ENV}`,
            linkText: () => "Se alle nyheter"
         }
      ]
   },
   contentImages: {
      cloudinaryMapping: "isdalen_images"
   },
   contactForm: {
      apiPath: "/api/is-contactform",
      reasons: [
         { value: "delivery", label: "Spørsmål angående levering", label_en: "Questions about ordering" },
         { value: "invoice", label: "Endring av fakturaadresse", label_en: "Invoice address changes" },
         { value: "invoice_credit", label: "Spørsmål angående faktura", label_en: "Invoice inquiries" },
         { value: "other", label: "Annet", label_en: "Other" },
         { value: "product_order", label: "Spørsmål angående bestillling", label_en: "Questions about ordering" },
         { value: "product_question", label: "Spørsmål angående produkter", label_en: "Question about products" },
         {
            value: "refrigeration_equipment",
            label: "Spørsmål angående kjølemøbler",
            label_en: "Questions about refrigeration equipment"
         }
      ]
   },
   whileYouWaitMessage: "Lag deg en cola-slush og nyt et par sekunders velfortjent pause!",
   notifications: {
      categories: [
         { id: "ORDER_CONFIRMATION", name: "Ordrebekreftelse" },
         { id: "DELIVERY_CONFIRMATION", name: "Leveringsbekreftelse" },
         { id: "IMPORTANT_INFO_ABOUT_DELIVERY", name: "Viktig melding om levering" }
      ],
      enableDeliveryMessages: false
   },
   cart: {
      customerReferenceLabel: "Din betalingsreferanse"
   }
};

export default isdalenSiteConfig;
