import { jsxs as d, jsx as s, Fragment as G } from "react/jsx-runtime";
import { generateUniqueClassName as y, generateGridColstyles as h, generateGridGapStyles as E, generateGridAlignStyles as N, generateGridJustifyStyles as b, cn as m } from "./designsystem-ui-react46.js";
import { useMemo as j } from "react";
import { GridCol as x } from "./designsystem-ui-react65.js";
const S = {
  media: "",
  container: "tw-@container"
};
function A({
  cols: f = 12,
  gap: w = 4,
  align: g,
  justify: u,
  type: e = "media",
  className: t,
  overflow: v = "visible",
  children: i,
  ...n
}) {
  const r = j(y, []), o = h(f, e), l = E(w, e), a = N(g, e), c = b(u, e), $ = e === "container", C = {
    visible: "tw-overflow-visible",
    hidden: "tw-overflow-hidden",
    auto: "tw-overflow-auto",
    scroll: "tw-overflow-scroll"
  };
  return $ ? /* @__PURE__ */ d("div", { className: "tw-@container", children: [
    /* @__PURE__ */ s(
      "div",
      {
        className: m(
          "tw-grid",
          C[v],
          r,
          S[e],
          t
        ),
        ...n,
        children: i
      }
    ),
    /* @__PURE__ */ s("style", { children: `.${r} { ${o} ${l} ${a} ${c} }` })
  ] }) : /* @__PURE__ */ d(G, { children: [
    /* @__PURE__ */ s("div", { className: m("tw-grid", r, S[e], t), ...n, children: i }),
    /* @__PURE__ */ s("style", { children: `.${r} { ${o} ${l} ${a} ${c} }` })
  ] });
}
A.Col = x;
export {
  A as Grid
};
