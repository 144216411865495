import { useState as u } from "react";
import { useIsoMorphicEffect as m } from "./designsystem-ui-react131.js";
import { useLatestValue as a } from "./designsystem-ui-react132.js";
function c(t, o) {
  let [s, r] = u(t), e = a(t);
  return m(() => r(e.current), [e, r, ...o]), s;
}
export {
  c as useComputed
};
