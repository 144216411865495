import { jsx as e, jsxs as t } from "react/jsx-runtime";
import { useState as z, useEffect as j } from "react";
import { getScreenSize as b, cn as l, isNotEmptyObject as E } from "./designsystem-ui-react46.js";
import { Tag as v } from "./designsystem-ui-react39.js";
import L from "./designsystem-ui-react82.js";
import { Rating as R } from "./designsystem-ui-react26.js";
function C({
  title: i = "",
  image: r,
  tag: w,
  time: n,
  rating: o,
  as: f = "a",
  compactRating: u = !1,
  href: h = "",
  className: g = "",
  horizontal: s,
  description: m,
  amountOfRatings: N,
  inverted: a,
  aspectRatio: x,
  ...y
}) {
  const [k, S] = z(b());
  function d() {
    S(b());
  }
  j(() => {
    if (typeof window < "u")
      return d(), window.addEventListener("resize", d), () => {
        window.removeEventListener("resize", d);
      };
  }, []);
  const c = k.width <= 768, p = f === "a";
  return /* @__PURE__ */ e(
    f,
    {
      ...p ? { href: h } : {},
      className: l(
        "tw-shadow-md tw-block tw-relative tw-no-underline tw-@container tw-rounded-card-lg tw-w-full tw-overflow-clip tw-min-h-full tw-max-w-96 tw-bg-base-0 tw-group",
        g,
        s ? "tw-max-w-none " : ""
      ),
      ...y,
      children: /* @__PURE__ */ t("div", { className: l("tw-flex", s ? "tw-flex-row" : "tw-flex-col"), children: [
        /* @__PURE__ */ e(
          "div",
          {
            className: l("tw-w-full tw-relative tw-bg-base-0 tw-overflow-hidden"),
            style: { aspectRatio: x || "5/4" },
            children: typeof r == "string" ? /* @__PURE__ */ e(
              "img",
              {
                alt: i,
                className: "tw-size-full tw-absolute tw-inset-0 tw-object-cover group-hover:tw-scale-105 tw-transition-all tw-duration-300",
                src: r
              }
            ) : /* @__PURE__ */ e("div", { className: "tw-w-full tw-h-full", children: r })
          }
        ),
        /* @__PURE__ */ t(
          "div",
          {
            className: l(
              "tw-flex tw-flex-col tw-px-2 tw-py-3 @sm:tw-py-5 @xl:tw-p-10",
              s ? "tw-gap-4" : "tw-gap-2",
              a ? "tw-bg-surface-default-inverted tw-text-ink-brand-inverted" : "tw-bg-base-0 tw-text-ink-brand-default"
            ),
            children: [
              /* @__PURE__ */ t("div", { className: "tw-flex tw-items tw-justify-between tw-gap-2 tw-w-full @md:tw-flex-col-reverse @md:tw-gap-6", children: [
                /* @__PURE__ */ t("div", { className: "tw-flex tw-items-center tw-gap-x-2", children: [
                  n ? /* @__PURE__ */ t(v, { icon: /* @__PURE__ */ e(L, {}), size: c ? "small" : "regular", variant: "base", children: [
                    n.label,
                    " ",
                    n.suffix ? n.suffix : null
                  ] }) : null,
                  E(w) ? /* @__PURE__ */ e(v, { size: c ? "small" : "regular", variant: "information", children: w == null ? void 0 : w.label }) : null
                ] }),
                o ? /* @__PURE__ */ e(
                  R,
                  {
                    amountOfRatings: N,
                    inverted: a,
                    isCompact: u || c,
                    rating: o
                  }
                ) : null
              ] }),
              /* @__PURE__ */ t("div", { className: "tw-flex tw-w-full tw-gap-2 tw-justify-between tw-items-start tw-flex-col @lg:tw-gap-6", children: [
                i ? /* @__PURE__ */ e(
                  "div",
                  {
                    className: l(
                      "tw-no-underline tw-w-full tw-text-left tw-outline-none focus-visible:tw-ring tw-rounded",
                      a ? "tw-text-ink-brand-inverted" : "tw-text-ink-brand-default",
                      p ? "group-hover:tw-underline" : ""
                    ),
                    children: /* @__PURE__ */ e("h2", { className: "tw-text-pretty tw-m-0 tw-line-clamp-2 tw-font-medium tw-text-base @md:tw-text-lg @lg:tw-text-3xl", children: i })
                  }
                ) : null,
                m ? /* @__PURE__ */ e("p", { className: "tw-hidden tw-text-ink-brand-default @xl:tw-block", children: m }) : null
              ] })
            ]
          }
        )
      ] })
    }
  );
}
C.displayName = "RecipeCard";
export {
  C as RecipeCard
};
