import { jsx as t, jsxs as n } from "react/jsx-runtime";
import { cn as u } from "./designsystem-ui-react46.js";
import { Tag as p } from "./designsystem-ui-react39.js";
function f({
  title: l,
  description: r,
  image: e,
  imageAlt: s,
  variant: i = "white",
  hasShadow: o = !0,
  href: c,
  className: d,
  tags: w
}) {
  const m = {
    white: "tw-bg-surface-default tw-text-ink-brand-default",
    transparent: "tw-bg-base-transparent",
    inverted: "tw-bg-button-primary-bg tw-text-button-primary-text"
  }[i];
  return /* @__PURE__ */ t("a", { href: c, children: /* @__PURE__ */ t(
    "article",
    {
      className: u(
        "tw-@container tw-rounded-card-lg  tw-overflow-clip tw-w-full tw-group tw-inline-block tw-group ",
        o && "tw-drop-shadow-lg",
        m,
        d
      ),
      children: /* @__PURE__ */ n("div", { className: "tw-flex @sm:tw-flex-col", children: [
        /* @__PURE__ */ t("div", { className: "tw-flex-shrink-0 tw-w-32 @sm:tw-w-full tw-relative tw-aspect-square @sm:tw-aspect-video tw-overflow-hidden", children: typeof e == "string" ? /* @__PURE__ */ t(
          "img",
          {
            alt: s,
            className: "tw-size-full tw-absolute tw-inset-0 tw-object-cover group-hover:tw-scale-105 tw-duration-300",
            src: e
          }
        ) : /* @__PURE__ */ t("div", { className: "tw-w-full tw-h-full", children: e }) }),
        /* @__PURE__ */ n("div", { className: "tw-flex tw-flex-col tw-gap-2 tw-p-3 @sm:tw-px-4 @sm:tw-pt-4 @sm:tw-pb-5 @xl:tw-items-start @xl:tw-justify-center", children: [
          w ? /* @__PURE__ */ t("div", { className: "tw-flex tw-gap-x-2", children: w.map((a) => /* @__PURE__ */ t(p, { textColor: "var(--color-ink-brand-default)", variant: "base", children: a }, a)) }) : null,
          l ? /* @__PURE__ */ t("h2", { className: "tw-text-xl @sm:tw-text-2xl tw-m-0 tw-line-clamp-2 @xl:tw-line-clamp-3 group-hover:tw-underline tw-underline-offset-4", children: /* @__PURE__ */ t("strong", { children: l }) }) : null,
          r ? /* @__PURE__ */ t("div", { className: "tw-hidden tw-text-lg @sm:tw-line-clamp-2", children: r }) : null
        ] })
      ] })
    }
  ) });
}
f.displayName = "ContentCard";
export {
  f as ContentCard
};
