import p, { createContext as ve, useState as he, useRef as J, useReducer as De, createRef as Ee, useContext as Z, useCallback as K, useEffect as L, useMemo as D } from "react";
import { FocusTrap as w } from "./designsystem-ui-react97.js";
import { useNestedPortals as ye, Portal as Y } from "./designsystem-ui-react98.js";
import { useDocumentOverflowLockedEffect as we } from "./designsystem-ui-react99.js";
import { useEvent as T } from "./designsystem-ui-react100.js";
import { useEventListener as Te } from "./designsystem-ui-react101.js";
import { useId as P } from "./designsystem-ui-react102.js";
import { useInert as V } from "./designsystem-ui-react103.js";
import { useOutsideClick as Pe } from "./designsystem-ui-react104.js";
import { useOwnerDocument as be } from "./designsystem-ui-react105.js";
import { useRootContainers as Ce } from "./designsystem-ui-react106.js";
import { useServerHandoffComplete as $e } from "./designsystem-ui-react107.js";
import { useSyncRefs as b } from "./designsystem-ui-react108.js";
import { useOpenClosed as Re, State as F } from "./designsystem-ui-react109.js";
import { ForcePortalRoot as x } from "./designsystem-ui-react110.js";
import { StackProvider as Se, StackMessage as X } from "./designsystem-ui-react111.js";
import { isDisabledReactIssue7711 as ke } from "./designsystem-ui-react112.js";
import { match as B } from "./designsystem-ui-react113.js";
import { Features as Q, forwardRefWithAs as C, render as $ } from "./designsystem-ui-react114.js";
import { Description as Oe, useDescriptions as Ie } from "./designsystem-ui-react115.js";
import { Keys as Ae } from "./designsystem-ui-react116.js";
var Fe = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(Fe || {}), Me = ((e) => (e[e.SetTitleId = 0] = "SetTitleId", e))(Me || {});
let Ne = { 0(e, o) {
  return e.titleId === o.id ? e : { ...e, titleId: o.id };
} }, M = ve(null);
M.displayName = "DialogContext";
function R(e) {
  let o = Z(M);
  if (o === null) {
    let l = new Error(`<${e} /> is missing a parent <Dialog /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(l, R), l;
  }
  return o;
}
function Ye(e, o, l = () => [document.body]) {
  we(e, o, (i) => {
    var r;
    return { containers: [...(r = i.containers) != null ? r : [], l] };
  });
}
function xe(e, o) {
  return B(o.type, Ne, e, o);
}
let Be = "div", Le = Q.RenderStrategy | Q.Static;
function Ue(e, o) {
  let l = P(), { id: i = `headlessui-dialog-${l}`, open: r, onClose: a, initialFocus: s, role: n = "dialog", __demoMode: f = !1, ...E } = e, [c, U] = he(0), _ = J(!1);
  n = function() {
    return n === "dialog" || n === "alertdialog" ? n : (_.current || (_.current = !0, console.warn(`Invalid role [${n}] passed to <Dialog />. Only \`dialog\` and and \`alertdialog\` are supported. Using \`dialog\` instead.`)), "dialog");
  }();
  let y = Re();
  r === void 0 && y !== null && (r = (y & F.Open) === F.Open);
  let g = J(null), ee = b(g, o), v = be(g), H = e.hasOwnProperty("open") || y !== null, j = e.hasOwnProperty("onClose");
  if (!H && !j)
    throw new Error("You have to provide an `open` and an `onClose` prop to the `Dialog` component.");
  if (!H)
    throw new Error("You provided an `onClose` prop to the `Dialog`, but forgot an `open` prop.");
  if (!j)
    throw new Error("You provided an `open` prop to the `Dialog`, but forgot an `onClose` prop.");
  if (typeof r != "boolean")
    throw new Error(`You provided an \`open\` prop to the \`Dialog\`, but the value is not a boolean. Received: ${r}`);
  if (typeof a != "function")
    throw new Error(`You provided an \`onClose\` prop to the \`Dialog\`, but the value is not a function. Received: ${a}`);
  let u = r ? 0 : 1, [S, te] = De(xe, { titleId: null, descriptionId: null, panelRef: Ee() }), h = T(() => a(!1)), q = T((t) => te({ type: 0, id: t })), k = $e() ? f ? !1 : u === 0 : !1, O = c > 1, G = Z(M) !== null, [oe, re] = ye(), ae = { get current() {
    var t;
    return (t = S.panelRef.current) != null ? t : g.current;
  } }, { resolveContainers: N, mainTreeNodeRef: I, MainTreeNode: le } = Ce({ portals: oe, defaultContainers: [ae] }), ne = O ? "parent" : "leaf", W = y !== null ? (y & F.Closing) === F.Closing : !1, ie = G || W ? !1 : k, se = K(() => {
    var t, m;
    return (m = Array.from((t = v == null ? void 0 : v.querySelectorAll("body > *")) != null ? t : []).find((d) => d.id === "headlessui-portal-root" ? !1 : d.contains(I.current) && d instanceof HTMLElement)) != null ? m : null;
  }, [I]);
  V(se, ie);
  let ue = O ? !0 : k, de = K(() => {
    var t, m;
    return (m = Array.from((t = v == null ? void 0 : v.querySelectorAll("[data-headlessui-portal]")) != null ? t : []).find((d) => d.contains(I.current) && d instanceof HTMLElement)) != null ? m : null;
  }, [I]);
  V(de, ue), Pe(N, (t) => {
    t.preventDefault(), h();
  }, !(!k || O));
  let pe = !(O || u !== 0);
  Te(v == null ? void 0 : v.defaultView, "keydown", (t) => {
    pe && (t.defaultPrevented || t.key === Ae.Escape && (t.preventDefault(), t.stopPropagation(), h()));
  }), Ye(v, !(W || u !== 0 || G), N), L(() => {
    if (u !== 0 || !g.current)
      return;
    let t = new ResizeObserver((m) => {
      for (let d of m) {
        let A = d.target.getBoundingClientRect();
        A.x === 0 && A.y === 0 && A.width === 0 && A.height === 0 && h();
      }
    });
    return t.observe(g.current), () => t.disconnect();
  }, [u, g, h]);
  let [ce, fe] = Ie(), me = D(() => [{ dialogState: u, close: h, setTitleId: q }, S], [u, S, h, q]), z = D(() => ({ open: u === 0 }), [u]), ge = { ref: ee, id: i, role: n, "aria-modal": u === 0 ? !0 : void 0, "aria-labelledby": S.titleId, "aria-describedby": ce };
  return p.createElement(Se, { type: "Dialog", enabled: u === 0, element: g, onUpdate: T((t, m) => {
    m === "Dialog" && B(t, { [X.Add]: () => U((d) => d + 1), [X.Remove]: () => U((d) => d - 1) });
  }) }, p.createElement(x, { force: !0 }, p.createElement(Y, null, p.createElement(M.Provider, { value: me }, p.createElement(Y.Group, { target: g }, p.createElement(x, { force: !1 }, p.createElement(fe, { slot: z, name: "Dialog.Description" }, p.createElement(w, { initialFocus: s, containers: N, features: k ? B(ne, { parent: w.features.RestoreFocus, leaf: w.features.All & ~w.features.FocusLock }) : w.features.None }, p.createElement(re, null, $({ ourProps: ge, theirProps: E, slot: z, defaultTag: Be, features: Le, visible: u === 0, name: "Dialog" }))))))))), p.createElement(le, null));
}
let _e = "div";
function He(e, o) {
  let l = P(), { id: i = `headlessui-dialog-overlay-${l}`, ...r } = e, [{ dialogState: a, close: s }] = R("Dialog.Overlay"), n = b(o), f = T((c) => {
    if (c.target === c.currentTarget) {
      if (ke(c.currentTarget))
        return c.preventDefault();
      c.preventDefault(), c.stopPropagation(), s();
    }
  }), E = D(() => ({ open: a === 0 }), [a]);
  return $({ ourProps: { ref: n, id: i, "aria-hidden": !0, onClick: f }, theirProps: r, slot: E, defaultTag: _e, name: "Dialog.Overlay" });
}
let je = "div";
function qe(e, o) {
  let l = P(), { id: i = `headlessui-dialog-backdrop-${l}`, ...r } = e, [{ dialogState: a }, s] = R("Dialog.Backdrop"), n = b(o);
  L(() => {
    if (s.panelRef.current === null)
      throw new Error("A <Dialog.Backdrop /> component is being used, but a <Dialog.Panel /> component is missing.");
  }, [s.panelRef]);
  let f = D(() => ({ open: a === 0 }), [a]);
  return p.createElement(x, { force: !0 }, p.createElement(Y, null, $({ ourProps: { ref: n, id: i, "aria-hidden": !0 }, theirProps: r, slot: f, defaultTag: je, name: "Dialog.Backdrop" })));
}
let Ge = "div";
function We(e, o) {
  let l = P(), { id: i = `headlessui-dialog-panel-${l}`, ...r } = e, [{ dialogState: a }, s] = R("Dialog.Panel"), n = b(o, s.panelRef), f = D(() => ({ open: a === 0 }), [a]), E = T((c) => {
    c.stopPropagation();
  });
  return $({ ourProps: { ref: n, id: i, onClick: E }, theirProps: r, slot: f, defaultTag: Ge, name: "Dialog.Panel" });
}
let ze = "h2";
function Je(e, o) {
  let l = P(), { id: i = `headlessui-dialog-title-${l}`, ...r } = e, [{ dialogState: a, setTitleId: s }] = R("Dialog.Title"), n = b(o);
  L(() => (s(i), () => s(null)), [i, s]);
  let f = D(() => ({ open: a === 0 }), [a]);
  return $({ ourProps: { ref: n, id: i }, theirProps: r, slot: f, defaultTag: ze, name: "Dialog.Title" });
}
let Ke = C(Ue), Ve = C(qe), Xe = C(We), Qe = C(He), Ze = C(Je), Pt = Object.assign(Ke, { Backdrop: Ve, Panel: Xe, Overlay: Qe, Title: Ze, Description: Oe });
export {
  Pt as Dialog
};
