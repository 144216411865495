import { defaultTo, find, isArray, isNaN, isNil, split, trim } from "lodash-es";
import isString from "lodash-es/isString";
import React, { ReactNode } from "react";

import { NextGenUser } from "./types/accessTypes";
import { BreakpointName, BreakpointValues } from "./types/breakpointTypes";
import { ORDER_UNIT, OrderChangeAction, OrderState, ORDERTYPE } from "./types/productOrderTypes";
import { CartItem, ProductAvailability } from "./types/productTypes";
import { generateSubscriptionName } from "./utils/dateUtils";

/** Attempts to generate a generic search term from a product name, by removing numbers from the product name */
export const makeGenericSearch = (searchString: string): string => {
   return split(searchString, " ")
      .filter((word) => !isFinite(parseInt(word[0])))
      .join(" ");
};

/** Ensures that the value is an array */
export const forceArray = <T>(val: T | T[]): T[] => (isArray(val) ? val : [val]);

const priceFormat = new Intl.NumberFormat("no-NO", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

/** Formats the OrderUnit into readable string */
export const formatUnit = (unit: ORDER_UNIT | string, short: boolean = true): string => {
   switch (unit) {
      case ORDER_UNIT.D:
      case ORDER_UNIT.DFP:
         return short ? "D-pak" : "per D-pak (eks mva)";
      case ORDER_UNIT.STK:
         return short ? "STK" : "per stk (eks mva)";
      case ORDER_UNIT.L:
         return short ? "Liter" : "per liter (eks mva)";
      case ORDER_UNIT.P:
         return short ? "Pall" : "per pall (eks mva)";
      case ORDER_UNIT.K:
         return short ? "Kartong" : "per kartong (eks mva)";
      case ORDER_UNIT.KG:
         return short ? "Kg" : "per Kg (eks mva)";
      case ORDER_UNIT.CS:
         return short ? "Kasse" : "per kasse (eks mva)";
      default:
         console.warn(`formatUnit does not recognise unit ${unit}`);
         return short ? "STK" : "per stk (eks mva)";
   }
};

/** Formats the BaseUnit into readable string */
export const formatBaseUnit = (baseUnit: ORDER_UNIT | string, amount: number): string => {
   switch (baseUnit) {
      case ORDER_UNIT.D:
      case ORDER_UNIT.DFP:
         return "D-pak";
      case ORDER_UNIT.STK:
         return "stk";
      case ORDER_UNIT.L:
         return "liter";
      case ORDER_UNIT.P:
         return amount > 1 ? "paller" : "pall";
      case ORDER_UNIT.K:
         return amount > 1 ? "kartonger" : "kartong";
      case ORDER_UNIT.KG:
         return "kg";
      case ORDER_UNIT.CS:
         return amount > 1 ? "kasser" : "kasse";
      default:
         console.warn(`formatBaseUnit does not recognise unit ${baseUnit}`);
         return "stk";
   }
};

export const safeStringify = (obj: any) => {
   const seen = new WeakSet<object>();

   return JSON.stringify(obj, (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
         if (seen.has(value)) {
            return "[Circular]";
         }
         seen.add(value);
      }
      return value;
   });
};

export const formatPrice = (price?: number | null, simplify: boolean = false) => {
   if (isNil(price) || isNaN(price)) {
      return "-";
   }

   const priceStr = priceFormat.format(price);

   if (simplify) {
      const decimalIndex = priceStr.lastIndexOf(",");
      return priceStr.substring(0, decimalIndex + 1) + "-";
   }
   return `kr ${priceStr}`;
};

export const formatPhoneNumber = (phoneNumber: string): string => {
   if (!isString(phoneNumber)) {
      return phoneNumber;
   }

   return phoneNumber.replace(/[^+\d]/g, "");
};

export const hasContent = (val: undefined | null | object | string | number): val is object | string | number => !!val;

export const formatOtp = (otp: string, prevOtp: string): string => {
   const cleanedOtp = otp.replace(/\D/g, "");

   return otp.length <= 6 ? cleanedOtp : prevOtp;
};

export const stripPossibleHtmlExtension = (path: string) => path.replace(".html", "");

export const generateSubscriptionObjectName = (
   sub:
      | {
           deliveryWeekdayNumber: number | string;
           deliveryIntervalDays: number | string;
        }
      | null
      | undefined
): string => {
   if (isNil(sub)) {
      return "";
   }
   return generateSubscriptionName(sub.deliveryWeekdayNumber, sub.deliveryIntervalDays);
};

/** Build an array of actions for TIP to perform (add, del, upd) */
export const createActionsFromCartChanges = (
   addedLines: CartItem[],
   changedLines: CartItem[],
   removedOrderLines: OrderState[],
   oldState: OrderState[]
): OrderChangeAction[] => {
   const actions: OrderChangeAction[] = [];

   addedLines.forEach((al) => {
      actions.push({
         action: "add",
         sku: al.sku,
         quantity: al.qty,
         orderLineUnit: al.unit
      });
   });
   /**
    *
    * Why are we generating payloads with both orderLineNumber and orderLineNumbers?
    *
    * Because the order API and the subscription API was not created with the same orderLineNumber(s) property name,
    * we add both so that we can reuse this function for both. This means we are always sending one field that the API
    * will ignore, but we get to avoid duplicating code.
    *
    */
   changedLines.forEach((cl) => {
      const oldStateLine = find(oldState, { sku: cl.sku });
      actions.push({
         action: "upd",
         orderLineNumber: oldStateLine && oldStateLine.combinedLines,
         orderLineNumbers: oldStateLine && oldStateLine.combinedLines,
         sku: cl.sku,
         quantity: cl.qty,
         orderLineUnit: cl.unit
      });
   });

   removedOrderLines.forEach((rl) => {
      actions.push({
         action: "del",
         sku: rl.sku,
         orderLineNumber: rl.combinedLines,
         orderLineNumbers: rl.combinedLines
      });
   });

   return actions;
};

export const formatEan = (ean: string): string => {
   if (!isString(ean)) {
      console.error(`Invalid EAN: ${ean}. Expected a string.`);
      return "";
   }

   if (ean.length !== 13) {
      console.warn(`Unexpected EAN length: ${ean.length}. Expected 13.`);
      return ean;
   }

   return ean.substring(0, 1) + " " + ean.substring(1, 7) + " " + ean.substring(7);
};

export const translateOrderType = (orderType: ORDERTYPE, storeName: string): string => {
   const orderTypeToHumanString: Record<ORDERTYPE, string> = {
      WEB: "Engangsordre",
      STD: `Opprettet av ${storeName}`,
      LEV: "Leverant-ordre",
      WAS: "Abonnement",
      EXP: "Ekspressordre",
      EXL: "Ekstralevering",
      HPN: "Henteordre",
      KOE: "Konsulentordre",
      RLG: "Reklameartikler",
      TUP: "Kundeprogram",
      XML: "EDI-ordre",
      ETT: "Etterregistrering"
   };
   return defaultTo(orderTypeToHumanString[orderType], orderType);
};

export type UnavailabilityReasonType = {
   headerUnavailabilityText: string;
   listTitleUnavailabilityText: string;
};

type LimitedAvailabilityType = "SOLD_OUT" | "LIMITED" | "BOTH";

export const buildUnavailableAlertBox = (unavailable: ProductAvailability[] | undefined): UnavailabilityReasonType => {
   const containsUnavailable = unavailable?.some((item) => !item.available && !item.partialAvailable);
   const containsPartialUnavailable = unavailable?.some((item) => item.partialAvailable);

   const type: LimitedAvailabilityType =
      containsPartialUnavailable && containsUnavailable ? "BOTH" : containsPartialUnavailable ? "LIMITED" : "SOLD_OUT";

   switch (type) {
      case "BOTH":
         return {
            headerUnavailabilityText: "er ikke tilgjengelig / har begrenset antall på lager",
            listTitleUnavailabilityText:
               "Dessverre er noen av produktene du ønsker å bestille ikke tilgjengelig – se detaljer under. Oppdater handlekurven og send inn ordren på nytt."
         };

      case "LIMITED":
         return {
            headerUnavailabilityText: "har begrenset antall på lager",
            listTitleUnavailabilityText: `${unavailable?.length === 1 ? "Ett" : "Noen"} av produktene du har bestilt har begrenset tilgjengelighet for øyeblikket.`
         };

      case "SOLD_OUT":
      default:
         return {
            headerUnavailabilityText: "er ikke tilgjengelig",
            listTitleUnavailabilityText:
               "Dessverre er noen av produktene du ønsker å bestille ikke tilgjengelig – se detaljer under. Oppdater handlekurven og send inn ordren på nytt."
         };
   }
};

export const translateDayLabel = (orderType: ORDERTYPE): string => {
   switch (orderType) {
      case ORDERTYPE.HPN:
         return "Hentes etter";
      default:
         return "Levering";
   }
};

export const translateStatus = (status: string, storeCompanyName: string): { text: string; color: string } => {
   const statusToHumanString: Record<string, { text: string; color: string }> = {
      "20": { text: `bestilling mottatt av ${storeCompanyName}`, color: "information" },
      "22": { text: `bestilling mottatt av ${storeCompanyName}`, color: "information" },
      "33": { text: `bestilling mottatt av ${storeCompanyName}`, color: "information" },
      "44": { text: "under plukking", color: "warning" },
      "66": { text: "ferdig pakket", color: "information" },
      "77": { text: "pakket og fakturert", color: "success" },
      "90": { text: "slettet", color: "error" },
      "99": { text: "kan ikke leveres", color: "error" }
   };
   return defaultTo(statusToHumanString[status], { text: `status ${status}`, color: "black" });
};

export const translateWeekday = (englishWeekday: string) => {
   const englishToNorwegianWeekdays: Record<string, string> = {
      Monday: "Mandag",
      Tuesday: "Tirsdag",
      Wednesday: "Onsdag",
      Thursday: "Torsdag",
      Friday: "Fredag",
      Saturday: "Lørdag",
      Sunday: "Søndag"
   };
   return defaultTo(englishToNorwegianWeekdays[englishWeekday], englishWeekday);
};

export const makeUserLabel = (user: NextGenUser) => {
   if (user.firstName && user.lastName) {
      return trim(defaultTo(user.firstName, " ").substring(0, 1) + defaultTo(user.lastName, " ").substring(0, 1));
   } else if (user.firstName) {
      return user.firstName.substring(0, 2);
   } else if (user.lastName) {
      return user.lastName.substring(0, 2);
   } else {
      const emailsParts = user.email.split("@")[0].split(".");
      if (emailsParts.length > 1) {
         return emailsParts[0].substring(0, 1) + emailsParts[1].substring(0, 1);
      } else {
         return emailsParts[0].substring(0, 2);
      }
   }
};

const stringToColor = (string: string, saturation: number = 75, lightness: number = 75): string => {
   let hash = 0;
   for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
   }
   return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`;
};

export const makeUserColor = (user: NextGenUser): string => stringToColor(makeUserLabel(user));

export const desktopBreakpoint = "(min-width: 768px)";
export const mediumBreakpoint = "(max-width: 767.98px)";
export const mobileBreakpoint = "(max-width: 639.98px)";
// Todo: remove, use mediaQueries below
export const twDesktopBreakpoint = "(min-width: 1024px)";

export const inRangeInclusive = (num: number, validRange: { min: number; max: number }): boolean =>
   num >= validRange.min && num <= validRange.max;

export const weekDayNames = ["Sø.", "Ma.", "Ti.", "On.", "To.", "Fr.", "Lø."];

export const monthNames = [
   "Januar",
   "Februar",
   "Mars",
   "April",
   "Mai",
   "Juni",
   "Juli",
   "August",
   "September",
   "Oktober",
   "November",
   "Desember"
];

export const breakpoints: { [name in BreakpointName]: BreakpointValues } = {
   xs: "",
   sm: "640px",
   md: "768px",
   lg: "1024px",
   xl: "1280px",
   xxl: "1536px",
   "2xl": "1536px"
};
export const mediaQueries: { [name in BreakpointName]: string } = {
   xs: "",
   sm: `(min-width: ${breakpoints.sm})`,
   md: `(min-width: ${breakpoints.md})`,
   lg: `(min-width: ${breakpoints.lg})`,
   xl: `(min-width: ${breakpoints.xl})`,
   xxl: `(min-width: ${breakpoints.xxl})`,
   "2xl": `(min-width: ${breakpoints["2xl"]})`
};

export const focusableElementCssSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const getYoutubeVideoIdFromURL = (url: string): string | undefined => {
   const [preQuery, postQuery] = url.split("?");
   if (/youtube\.com/i.test(preQuery) && postQuery) {
      return postQuery
         .split("&")
         .find((param) => param.startsWith("v="))
         ?.substring(2);
   }
};

export const titleCase = (str: string): string => {
   const lower = str.toLowerCase();
   return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const createRandomHex = (size: number) => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

export function getKeyboardFocusableElements(element: Element | Document = document) {
   return [
      ...element.querySelectorAll(
         'a, button:not([tabindex="-1"]), input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
      )
   ].filter((el) => !el.hasAttribute("disabled"));
}

export const extractTextFromReactNode = (node: ReactNode): string => {
   if (typeof node === "string") {
      return node;
   } else if (Array.isArray(node)) {
      return node.map(extractTextFromReactNode).join("");
   } else if (React.isValidElement(node)) {
      return extractTextFromReactNode(node.props.children);
   }
   return "";
};
