import Bugsnag from "@bugsnag/js";
import { autoEffect, batch, store } from "@risingstack/react-easy-state";
import { parseISO } from "date-fns";
import { truncate } from "lodash-es";
import debounce from "lodash-es/debounce";
import defaultTo from "lodash-es/defaultTo";
import find from "lodash-es/find";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import pick from "lodash-es/pick";
import remove from "lodash-es/remove";

import { apiClient } from "../../common/apiClient";
import { sendAddToCart, sendCartCleared, sendMiniCartVisible, sendRemoveFromCart } from "../../common/tracking";
import {
   M3Order,
   M3SubscriptionListed,
   M3SubscriptionOptimizedOrderLine,
   M3SubscriptionRaw,
   SubscriptionStoreOrderItem
} from "../../common/types/m3Types";
import {
   ChangeOrderPayload,
   EditMode,
   ORDER_UNIT,
   OrderChangeAction,
   OrderState,
   ORDERTYPE
} from "../../common/types/productOrderTypes";
import {
   CartItem,
   CartItemDelta,
   CartTotals,
   ExpandedCartItem,
   LostSaleItem,
   ProductAvailability
} from "../../common/types/productTypes";
import { GAListInformation, LIST_TYPE } from "../../common/types/trackingTypes";
import { createActionsFromCartChanges, createRandomHex } from "../../common/utils";
import { parseM3Date } from "../../common/utils/dateUtils";
import {
   getEditModeFromStorage,
   getOrdertypeFromStorage,
   setEditmodeInStorage,
   setOrderTypeInStorage
} from "../../common/utils/storageUtils";

import theme from "../../themes/theme";
import authStore from "../auth/authStore";
import loginState from "../auth/loginState";
import availabilityStore from "../availabilityStore";
import deliveryDatesStore from "../deliveryDates/deliveryDatesStore";
import deliveryFeeStore from "../deliveryFeeStore";
import orderStore from "../orders/orderStore";
import productStore from "../product/productStore";
import promotionStore from "../promotionStore";
import subscriptionStore from "../subscriptionStore";
import toastStore from "../toastStore";
import { getCartTotal } from "./cartUtils";

type CartStore = {
   items: CartItem[];
   orderType: ORDERTYPE;
   miniCartVisible: boolean;
   sendingOrder: boolean;
   cancelled: string[];
   editing: EditMode | null;
   orderRefs: {
      orderName: string;
      customerReference: string;
      customerOrderNumber: string;
   };
   lostSales: LostSaleItem[];

   getCartItem(sku: string): CartItem | undefined;
   resetOrderRefs(): void;
   deliveryDateForPicker(): Date[];
   getCartTotal(): CartTotals;
   getCartTotalBeforeDiscount(): CartTotals;
   startEditOrderMode(m3Order: M3Order): Promise<void>;
   startEditSubscriptionMode(subscription: M3SubscriptionRaw, instanceDate?: string, existingInstance?: boolean): Promise<void>;
   getValidQtyRange(sku: string): { min: number; max: number };
   isEditing(): boolean;
   isEditingInstance(): boolean;
   isAddedDuringEdit(sku: string): boolean;
   hasQtyChangedDuringEdit(sku: string): boolean;
   getEditingCartChanges(): CartItemDelta[];
   generateChangeOrderPayload(): ChangeOrderPayload | null;
   updateCartDependencies(): void;
   stopEditOrderMode(): Promise<string>;
   changeOrderType(newOrderType: ORDERTYPE): void;
   findExisitingSubscription(): M3SubscriptionListed[];
   emptyCart(): void;
   addToCart(sku: string, qty: number, delay?: number): boolean;
   loadCartFromServer(): Promise<void>;
   saveCartToServer(): void;
   saveCartToServerWithoutDelay(): void;
   removeFromCart(sku: string): void;
   applyAvailabilityChanges(): void;
   submitOrder(): Promise<string[]>;
   showMiniCart(): void;
   hideMiniCart(): void;
   isEditingCartUnchanged(): boolean;
   addLostSales(unavailable: ProductAvailability[]): void;
};

const cartStore: CartStore = store({
   items: [],
   orderType: getOrdertypeFromStorage(),
   miniCartVisible: false,
   cancelled: [],
   sendingOrder: false,
   editing: getEditModeFromStorage(),
   orderRefs: {
      orderName: "",
      customerReference: "",
      customerOrderNumber: ""
   },
   lostSales: [],

   getCartItem: (sku) => {
      return find(cartStore.items, { sku });
   },

   resetOrderRefs: () => {
      cartStore.orderRefs = {
         orderName: "",
         customerReference: "",
         customerOrderNumber: ""
      };
   },

   deliveryDateForPicker: () => {
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         return [];
      }
      return [currentDelivery.date];
   },

   getCartTotalBeforeDiscount: () => {
      const cartItems = cartStore.items;
      return getCartTotal(cartItems, true);
   },

   getCartTotal: () => {
      const cartItems = cartStore.items;
      return getCartTotal(cartItems, false);
   },

   startEditOrderMode: async (m3Order) => {
      if (cartStore.editing !== null) {
         console.warn("Something unexpected happened, we are trying to start editing an order while editing");
      }

      // Extract the neccesary data from the m3 order objekt from TIP API
      const orderNumber = m3Order.orderNumber;
      const deadline = m3Order.deadline;
      const orderItems = m3Order.optimizedOrderLines
         .map((ol) => pick(ol, ["sku", "combinedOrderLineNumbers", "canDecrease", "canIncrease", "canDelete", "totalQuantity"]))
         .map((ol) => ({
            ...ol,
            qty: ol.totalQuantity,
            combinedLines: ol.combinedOrderLineNumbers
         }));

      await deliveryDatesStore.changeDeliveryDate(parseISO(m3Order.requestedDeliveryDate), cartStore.orderType);

      // Filling in the editing key turns the store over to "edit mode"
      cartStore.editing = {
         orderNumber,
         deadline,
         orderType: m3Order.orderType,
         oldState: orderItems,
         canAddItems: true
      };

      // Set up cart contents with the items from the order we are editing
      cartStore.emptyCart();
      orderItems.forEach((oi) => {
         cartStore.addToCart(oi.sku, oi.qty, 0);
      });
      // We need to set the canAddItems after we have added all the items to the cart to avoid getting blocked
      cartStore.editing = {
         ...cartStore.editing,
         canAddItems: m3Order.canAddOrderline
      };

      cartStore.orderRefs = {
         orderName: orderStore.orderDetails[m3Order.orderNumber].data?.orderName ?? "",
         customerReference: orderStore.orderDetails[m3Order.orderNumber].data?.customerReference ?? "",
         customerOrderNumber: orderStore.orderDetails[m3Order.orderNumber].data?.customerOrderNumber ?? ""
      };

      setEditmodeInStorage(cartStore.editing);
   },

   startEditSubscriptionMode: async (subscription, instanceDate, existingInstance = false) => {
      if (cartStore.editing !== null) {
         console.warn("Something unexpected happened, we are trying to start editing an order while editing");
      }

      cartStore.changeOrderType(ORDERTYPE.WAS);
      let orderDetails: M3SubscriptionRaw;
      let changeToDate: Date;

      if (isNil(instanceDate)) {
         console.log("Starting edit of main subscription " + subscription.orderNumber);
         const nextDeliveryDate = deliveryDatesStore.getNextMatchingDeliveryDate(subscription.deliveryWeekdayNumber);
         if (isNil(nextDeliveryDate)) {
            toastStore.addError(
               "Det oppsto en feil",
               "Dessverre greier vi ikke å starte redigering av dette abonnementet pga en utfordring med leveringsdagene."
            );
            return;
         }

         changeToDate = nextDeliveryDate.date;
         orderDetails = subscription;
      } else {
         changeToDate = parseM3Date(instanceDate);
         if (existingInstance) {
            console.log("Starting edit of existing " + instanceDate + " instance of subscription " + subscription.orderNumber);
            orderDetails = await subscriptionStore.fetchInstanceOrder(subscription.orderNumber, instanceDate);
         } else {
            console.log("Starting creation of new " + instanceDate + " instance of subscription " + subscription.orderNumber);
            orderDetails = subscription;
         }
      }

      await deliveryDatesStore.changeDeliveryDate(changeToDate, ORDERTYPE.WAS);
      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         toastStore.addError("Det oppsto en feil", "Det oppsto en feil etter endring av leveringsdag.");
         return;
      }
      currentDelivery.interval = parseInt(subscription.deliveryIntervalDays);

      const orderItems = orderDetails.optimizedOrderLines
         .map(
            (ol: M3SubscriptionOptimizedOrderLine): SubscriptionStoreOrderItem =>
               pick(ol, ["sku", "combinedOrderLineNumbers", "canDecrease", "canIncrease", "canDelete", "totalQuantity"])
         )
         .map((ol) => ({
            ...ol,
            qty: ol.totalQuantity,
            combinedLines: ol.combinedOrderLineNumbers
         }));

      // Set up cart contents with the items from the order we are editing
      cartStore.emptyCart();
      orderItems.forEach((oi: SubscriptionStoreOrderItem) => {
         cartStore.addToCart(oi.sku, defaultTo(oi.qty, 1), 0);
      });

      // Filling in the editing key turns the store over to "edit mode"
      cartStore.editing = {
         orderNumber: orderDetails.orderNumber,
         orderType: ORDERTYPE.WAS,
         instanceDate,
         existingInstance,
         // The difference between these two is the s at the end, unfortuantely the two APIs behave differently.
         canAddItems: defaultTo(orderDetails.canAddOrderline, orderDetails.canAddOrderlines) ?? false,
         oldState: orderItems
      };

      cartStore.orderRefs = {
         orderName: "",
         customerReference: subscriptionStore.subscriptionDetails[subscription.orderNumber].data?.customerReference ?? "",
         customerOrderNumber: ""
      };

      setEditmodeInStorage(cartStore.editing);
   },

   getValidQtyRange: (sku) => {
      let min = 1;
      let max = Number.MAX_SAFE_INTEGER;
      if (!cartStore.isEditing()) {
         return { min, max };
      }

      const item = find(cartStore.editing!.oldState, { sku });
      if (isUndefined(item)) {
         return { min, max };
      }

      if (!item.canIncrease) {
         max = item.qty;
      }

      if (!item.canDecrease) {
         min = item.qty;
      }

      return { min, max };
   },

   isEditing: () => {
      return cartStore.editing !== null;
   },

   isEditingInstance: () => {
      return !isNil(cartStore.editing) && !isNil(cartStore.editing.instanceDate);
   },

   isAddedDuringEdit: (sku) => {
      if (isNil(cartStore.editing)) {
         return true;
      }
      const previousOrder = find(cartStore.editing.oldState, { sku });
      return isNil(previousOrder);
   },

   hasQtyChangedDuringEdit: (sku) => {
      if (isNil(cartStore.editing)) {
         return false;
      }

      const previousOrder = find(cartStore.editing.oldState, { sku });
      if (isNil(previousOrder)) {
         // Product was not in previous order at all, so mark this as not a change
         return false;
      }

      const currentCart = cartStore.getCartItem(sku);
      if (isNil(currentCart)) {
         return false;
      }

      // Only true if the cart qty has changed
      return currentCart.qty !== previousOrder.qty;
   },

   getEditingCartChanges: () => {
      if (cartStore.editing) {
         const oldState: OrderState[] = cartStore.editing.oldState;
         const newAndChanged: CartItemDelta[] = cartStore.items
            .map((item) => {
               const orderLine = oldState.find((orderLine) => orderLine.sku === item.sku);
               if (orderLine) {
                  return { ...item, qtyDelta: item.qty - orderLine.qty };
               } else {
                  return { ...item, qtyDelta: item.qty };
               }
            })
            .filter((cartItemDelta) => cartItemDelta.qtyDelta !== 0);
         const removed: CartItemDelta[] = oldState
            .filter((orderLine) => !cartStore.items.find((item) => item.sku === orderLine.sku))
            .map((orderLine) => ({
               sku: orderLine.sku,
               qty: 0,
               fromEditing: true,
               qtyDelta: -orderLine.qty,
               unit: productStore.resolveSku(orderLine.sku)?.unit || ORDER_UNIT.D
            }));
         return [...newAndChanged, ...removed];
      }
      return [];
   },

   generateChangeOrderPayload: () => {
      if (isNil(cartStore.editing) || isNil(authStore.currentCompany) || isNil(cartStore.editing.orderNumber)) {
         return null;
      }

      const changedLines = cartStore.items.filter((ci) => cartStore.hasQtyChangedDuringEdit(ci.sku));
      console.log("Changed lines: ", changedLines);

      const addedLines = cartStore.items.filter((ci) => cartStore.isAddedDuringEdit(ci.sku));
      console.log("Added lines: ", addedLines);

      const removedOrderLines = cartStore.editing.oldState.filter((os) => isUndefined(find(cartStore.items, { sku: os.sku })));
      console.log("Removed order lines: ", removedOrderLines);

      if (removedOrderLines.length === 0 && changedLines.length === 0 && addedLines.length === 0) {
         return null;
      }

      const actions: OrderChangeAction[] = createActionsFromCartChanges(
         addedLines,
         changedLines,
         removedOrderLines,
         cartStore.editing.oldState
      );

      cartStore.lostSales.forEach((lostSale) => {
         actions.push({
            action: "add",
            lostSalesUniqueId: createRandomHex(10),
            ...lostSale
         });
      });

      return {
         companyNumber: theme.m3CompanyNumber,
         customerNumber: authStore.currentCompany,
         orderNumber: cartStore.editing.orderNumber,
         estimatedOrderAmount: 2500,
         orderLines: actions
      } satisfies ChangeOrderPayload;
   },

   stopEditOrderMode: async () => {
      if (isNil(cartStore.editing) || isNil(cartStore.orderType)) {
         console.warn("Wrong state to stop edit mode, editing: " + cartStore.editing + ", orderType: " + cartStore.orderType);
         return Promise.reject("Wrong state to stop edit mode");
      }
      const orderNumber = cartStore.editing.orderNumber;
      if (isNil(orderNumber)) {
         return Promise.reject("Unable to determine what order number was being edited");
      }

      cartStore.emptyCart();
      cartStore.editing = null;
      setEditmodeInStorage(cartStore.editing);

      deliveryDatesStore.selectNextDeliveryDateForOrderType(cartStore.orderType);

      return Promise.resolve(orderNumber);
   },

   changeOrderType: (newOrderType) => {
      if (!loginState.is("DELIVERY_DATES_LOADING") || loginState.is("LOGGED_IN")) {
         console.warn("Unexpected order type change?");
      }

      batch(() => {
         // const currentDelivery = deliveryDatesStore.getCurrentDelivery();
         cartStore.orderType = newOrderType;
         availabilityStore.clearAvailability();
         setOrderTypeInStorage(newOrderType);

         const deliveryForType = deliveryDatesStore.getCurrentDelivery(newOrderType);
         const expiration = deliveryDatesStore.getCurrentDeliveryExpiration(newOrderType);
         if (deliveryForType && expiration) {
            void deliveryDatesStore.changeDeliveryDate(deliveryForType.date, newOrderType);
         } else {
            deliveryDatesStore.selectNextDeliveryDateForOrderType(newOrderType);
         }
         // Find the next possible delivery date for this order type

         if (newOrderType === ORDERTYPE.WAS) {
            availabilityStore.clearAvailability();
            if (deliveryForType) {
               deliveryForType.interval = 7;
            }
            promotionStore.clearDisounts();
         }
      });

      // If this runs while we are fully logged in, we should refetch the cart depdenencies
      if (loginState.is("LOGGED_IN")) {
         void cartStore.updateCartDependencies();
      }

      console.log("Selected delivery", deliveryDatesStore.getCurrentDelivery());
   },

   findExisitingSubscription: () => {
      if (cartStore.orderType !== ORDERTYPE.WAS || cartStore.isEditing()) {
         return [];
      }

      const currentDelivery = deliveryDatesStore.getCurrentDelivery();
      if (isNil(currentDelivery)) {
         return [];
      }
      return subscriptionStore.subscriptionsList.data.filter(
         (subscription) =>
            currentDelivery.interval === parseInt(subscription.deliveryIntervalDays) &&
            currentDelivery.date.getDay() === parseInt(subscription.deliveryWeekdayNumber)
      );
   },

   emptyCart: () => {
      cartStore.items = [];
      if (authStore.isLoggedIn()) {
         cartStore.saveCartToServerWithoutDelay();
         cartStore.updateCartDependencies();
      }
   },

   addToCart: (sku, qty, delay = 800) => {
      const cartItem = cartStore.getCartItem(sku);
      const alreadyInCart = !isUndefined(cartItem);

      const product = productStore.resolveSku(sku);
      if (isUndefined(product)) {
         console.warn("Tried to add unavailable product to cart, sku " + sku);
         return false;
      }
      const unit = product.unit;

      if (!isNil(cartStore.editing) && !cartStore.editing.canAddItems) {
         toastStore.addError(
            "Ordren har begrensninger",
            "Ordren som du redigerer har begrensninger som gjør at du ikke kan legge til nye varelinjer.",
            { context: "order", text: "order_limitation_error" }
         );
         return false;
      }

      if (delay === 0) {
         if (alreadyInCart) {
            cartItem.qty += qty;
         } else {
            cartStore.items.push({
               sku,
               qty,
               unit,
               fromEditing: cartStore.isEditing()
            });
         }
         cartStore.saveCartToServer();
         cartStore.updateCartDependencies();
         return true;
      }

      // Delay adding the product to cart to match animation
      setTimeout(() => {
         if (alreadyInCart) {
            cartItem.qty += qty;
         } else {
            cartStore.items.push({
               sku,
               qty,
               unit,
               fromEditing: cartStore.isEditing()
            });
         }

         cartStore.saveCartToServer();
         cartStore.updateCartDependencies();
      }, delay);
      return true;
   },

   loadCartFromServer: async () => {
      if (isNil(authStore.currentCompany)) {
         console.warn("Attempted to load cart without being logged in");
         return Promise.reject();
      }

      return apiClient(`${process.env.API_HOST}/api/carts/${authStore.currentCompany}`, authStore.getSessionToken())
         .get()
         .json((cartFromServer: CartItem[]) => {
            console.log("Received cart from server: ", cartFromServer);

            // Avoid loading cart items from a previous editing session, if we are not editing
            cartStore.items = cartFromServer.filter((item) => item.fromEditing === cartStore.isEditing());

            // If we received items from a different editing state, we need to let the customer know and save the new cart.
            if (cartStore.items.length !== cartFromServer.length) {
               sendCartCleared();
               console.log("Received cart items with incorrect state from server, saving updated cart to server.");

               cartStore.saveCartToServer();
               toastStore.addToast(
                  "Endringer i handlekurven",
                  "Handlekurven din har blitt endret.\nDette kan skje om du jobber i to faner samtidig mens du endrer en eksisterende ordre eller om du tidligere har lukket nettleseren under endring av ordre.\nVennligst dobbelsjekk innholdet i handlekurven.",
                  "error",
                  true
               );
            }

            cartStore.updateCartDependencies();
         });
   },

   saveCartToServer: debounce(() => {
      cartStore.saveCartToServerWithoutDelay();
   }, 1000),

   saveCartToServerWithoutDelay: () => {
      if (!authStore.isLoggedIn()) {
         console.warn("Attempted to save cart without being logged in");
         return;
      }

      // Add price and vatRate to the server-side cart so we can use them to build punchout cart on the backend
      const payload: ExpandedCartItem[] = cartStore.items.map((item) => {
         const product = productStore.resolveSku(item.sku);
         return {
            ...item,
            fromEditing: cartStore.isEditing(),
            price: product?.price ?? null,
            vatRate: product?.vatRate ?? null
         };
      });

      console.log("Saving cart with payload:", payload);

      apiClient(`${process.env.API_HOST}/api/carts/${authStore.currentCompany}`, authStore.getSessionToken())
         .post(payload)
         .json((res) => {
            if (res) {
               console.log("Remote cart has been updated");
            } else {
               console.warn("Something went wrong when storing cart");
            }
         })
         .catch((err) => {
            console.warn("Unable to store cart remotely", err);
         });
   },

   updateCartDependencies: () => {
      deliveryFeeStore.recalculateDeliveryCharge();
      promotionStore.fetchPromotionDiscounts();
   },

   removeFromCart: (sku) => {
      const cartItem = find(cartStore.items, { sku });
      if (isUndefined(cartItem)) {
         console.warn("Attempting to remove SKU that is not in the cart, bug?");
         return;
      }

      remove(cartStore.items, { sku });
      cartStore.saveCartToServer();
      cartStore.updateCartDependencies();
   },

   addLostSales: (unavailable: ProductAvailability[]) => {
      unavailable.forEach((availability) => {
         const product = productStore.resolveSku(availability.productNumber);
         if (product) {
            const quantity = availability.partialAvailable
               ? availability.requestedQuantity - availability.availableQuantity
               : availability.requestedQuantity;
            const existingLostSales = cartStore.lostSales.find((lostSale) => lostSale.sku === availability.productNumber);
            if (existingLostSales) {
               existingLostSales.quantity += quantity;
            } else {
               cartStore.lostSales.push({
                  quantity,
                  sku: availability.productNumber,
                  isLostSales: true as const,
                  lostSalesWareHouse: availability.supplyingWarehouse || availability.warehouse || "",
                  orderLineUnit: product.salesUnit
               });
            }
         }
      });
   },

   applyAvailabilityChanges: () => {
      if (isNil(availabilityStore.availability)) {
         console.warn("Unable to apply availability, it's already null...");
         return;
      }

      let itemsAdded: { sku: string; qty: number }[] = [];
      let itemsRemoved: { sku: string; qty: number }[] = [];
      const listInformation: GAListInformation = {
         type: LIST_TYPE.AVAILABILITY_CHECK,
         name: "Tilgjengelighetskontroll",
         length: availabilityStore.availability.length
      };
      console.log("Applying availability changes", availabilityStore.availability, availabilityStore.newCartItems);

      availabilityStore.availability.forEach((productAvailability) => {
         const cartItem = cartStore.getCartItem(productAvailability.productNumber);
         if (!cartItem) {
            if (productAvailability.selectedQuantity) {
               cartStore.addToCart(productAvailability.productNumber, productAvailability.selectedQuantity);
               itemsAdded.push({ sku: productAvailability.productNumber, qty: productAvailability.selectedQuantity });
            }
         } else {
            if (productAvailability.selectedQuantity) {
               if (cartItem.qty < productAvailability.selectedQuantity) {
                  itemsAdded.push({
                     sku: productAvailability.productNumber,
                     qty: productAvailability.selectedQuantity - cartItem.qty
                  });
               } else {
                  const product = productStore.resolveSku(productAvailability.productNumber);
                  product && itemsRemoved.push({ sku: product.sku, qty: cartItem.qty - productAvailability.selectedQuantity });
               }
               cartItem.qty = productAvailability.selectedQuantity;
            } else {
               cartStore.removeFromCart(productAvailability.productNumber);
               const product = productStore.resolveSku(productAvailability.productNumber);
               product && itemsRemoved.push({ sku: product.sku, qty: cartItem.qty });
            }
         }
      });

      for (let sku in availabilityStore.newCartItems) {
         const item = cartStore.items.find((item) => item.sku === sku);
         if (item) {
            if (availabilityStore.newCartItems[sku].qty) {
               item.qty += availabilityStore.newCartItems[sku].qty;
            }
         } else {
            if (availabilityStore.newCartItems[sku].qty) {
               cartStore.items.push({ ...availabilityStore.newCartItems[sku] });
            }
         }
         itemsAdded.push({ sku, qty: availabilityStore.newCartItems[sku].qty });
      }
      itemsAdded.length && sendAddToCart(itemsAdded, listInformation);
      itemsRemoved.length && sendRemoveFromCart(itemsRemoved, listInformation);

      availabilityStore.clearAvailability();
   },

   submitOrder: async () => {
      // Subscription
      if (cartStore.orderType === "WAS") {
         // Changing one planned delivery of a subscription (subscription instance)
         if (cartStore.isEditingInstance()) {
            if (!isNil(cartStore.editing) && cartStore.editing.existingInstance) {
               return subscriptionStore.submitInstanceChanges();
            } else {
               return subscriptionStore.submitNewInstanceOrder();
            }
         }

         // Normal subscription
         if (cartStore.isEditing()) {
            return subscriptionStore.submitSubscriptionChanges();
         } else {
            return subscriptionStore.submitNewSubscription();
         }
      }

      // One-time order
      if (
         cartStore.orderType === ORDERTYPE.WEB ||
         cartStore.orderType === ORDERTYPE.HPN ||
         cartStore.orderType === ORDERTYPE.LEV
      ) {
         return cartStore.isEditing() ? orderStore.submitOrderChanges() : orderStore.submitNewOrder();
      }

      toastStore.addError(
         "Ukjent ordretype aktiv",
         "Ordretypen som er valgt (" + cartStore.orderType + ") er ikke kjent, og vi får ikke plassert ordren.",
         { context: "order", text: "unknown_order_type_error" }
      );
      Bugsnag.notify(new Error("Unknown order type [" + cartStore.orderType + "]"));
      return Promise.reject();
   },

   showMiniCart: () => {
      sendMiniCartVisible();
      cartStore.miniCartVisible = true;
   },

   hideMiniCart: () => {
      cartStore.miniCartVisible = false;
   },
   isEditingCartUnchanged: () => {
      const { editing } = cartStore;
      if (editing) {
         if (cartStore.items.length === editing.oldState.length) {
            return cartStore.items.every((item) =>
               editing.oldState.find((orderState) => orderState.sku === item.sku && orderState.qty === item.qty)
            );
         }
      }
      return false;
   }
});

autoEffect(() => {
   cartStore.orderRefs.customerOrderNumber = truncate(cartStore.orderRefs.customerOrderNumber, {
      length: 20,
      omission: ""
   });
   cartStore.orderRefs.customerReference = truncate(cartStore.orderRefs.customerReference, {
      length: 30,
      omission: ""
   });
   cartStore.orderRefs.orderName = truncate(cartStore.orderRefs.orderName, { length: 36, omission: "" });
});

// Expose the emptyCart function to the global scope for punchout purposes
window.emptyCart = cartStore.emptyCart;

export default cartStore;
